import React, { useState } from "react";

const ReadMore = ({ text, maxLength, showReadMore, classes }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  return (
    <div className="">
      <p className={`${classes} m-0 word-break text-ellipsis-1`}>
        {isTruncated
          ? text.length > maxLength
            ? `${text.slice(0, maxLength)}...`
            : text
          : text}
        <b>
          {showReadMore && (
            <span
              onClick={toggleTruncate}
              style={{ color: "black", cursor: "pointer" }}
            >
              {isTruncated ? " Read more" : " Read less"}
            </span>
          )}
        </b>
      </p>
    </div>
  );
};

ReadMore.defaultProps = {
  showReadMore: true,
  classes: "",
};

export default ReadMore;
