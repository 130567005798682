import { useState } from "react";
import ReactPlayer from "react-player";
import ModalWrapper from "../../Modals/ModalWrapper";
import VideoModal from "../../Modals/VideoModal";

function ContentProductCard({ video, title, category }) {
  const [openModal, setOpenModal] = useState(false);
  const openModalHandler = () => setOpenModal((prev) => !prev);

  return (
    <>
      <div
        onClick={openModalHandler}
        className="d-flex d-flex-column rounded-12 box-shadow2 o-hidden bg-white"
        style={{
          boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.07)",
          width: "100%",
        }}
      >
        <div
          className="p-relative rounded-top-12"
          style={{ width: "100%", height: "124px" }}
        >
          {/* Wrapping ReactPlayer with a div to handle clicks */}
          <ReactPlayer
            width="100%"
            height={124}
            url={video}
            playing={false} // Prevent autoplay
            controls={false} // Disable controls
          />
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: "0",
              left: "0",
            }}
            className="d-flex d-align-center d-justify-center"
          >
            <img className="cursor-pointer" src="/assets/playIcon.svg" alt="play-icon" />
          </div>
        </div>

        <div
          style={{ height: "auto" }}
          className="d-flex d-flex-column bg-white p-3 rounded-bottom-20"
        >
          {/* Title text */}
          {title && (
            <div className="font-16 f-600 l-24 ls-1">{title}</div>
          )}

          {category?.name && (
            <div className="ox-scroll o-hidden max-w-full d-flex d-align-center gap-1 hide-scrollbar">
              <div
                className="d-flex gap-1 p-1 rounded-5 d-align-center opacity-03 w-fit"
                style={{ background: "#F1FBFA", minWidth: "fit-content" }}
              >
                <div
                  className="d-flex rounded-50 bg-white d-justify-center"
                  style={{ width: "20px", height: "20px" }}
                >
                  <img src="/assets/PodcastGreen.svg" />
                </div>
                <div className="font-14 text-sea-green">{category?.name}</div>
              </div>
            </div>
          )}
        </div>
      </div>

      {openModal && (
        <ModalWrapper>
          <VideoModal
            url={video}
            closeHandler={() => setOpenModal(false)}
          ></VideoModal>
        </ModalWrapper>
      )}
    </>
  );
}

export default ContentProductCard;
