
function References({
  name,
  designation,
  companyName,
  companyPic,
  userPic,
  linkedinUrl,
}) {
  return (
    <div className="col-12 d-flex d-justify-space-between gap-1 rounded-12 bg-white p-3">
      {/* Person details */}

      <div className="d-flex d-justify-space-between d-align-center gap-1">
        {/* Profile Image */}

        <img
          src={userPic}
          className="rounded-50 m-auto w-full h-full rounded-50"
          style={{ width: "60px", height: "60px", objectFit: "contain" }}
        />

        {/* Person name and work */}
        <div className="d-flex d-flex-column">
          <div className="font-20 f-600 card-text-wrap">{name}</div>
          <div className="font-14 f-400 card-text-wrap">{designation}</div>
            <div className="font-14 f-500">
              {companyName}
            </div>
        </div>
      </div>

     
    </div>
  );
}
  
  export default References;
  