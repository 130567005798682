// import {
//     getCompanyCookie,
//     setOnBoardCookie,
//     removeOnBoardCookie,
//   } from "auth/userCookies";
import {
  getCompanyCookie,
  getToken,
  removeToken,
  setToken,
} from "../auth/cookies";
import firebase from "../auth/firebaseConfig";
import { toastError } from "./Toasters";
//   import { toastError } from "@/Components/Toasters";

// const BASE_URL = 'http://54.86.255.217:8090/v1/'
const BASE_URL = "https://api.dev.futrconnect.com/v1/";

export async function tokenValidator() {
  var token = await getToken();
  if (token) {
    function parseJwt() {
      if (!token) {
        return;
      }
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      return JSON.parse(window.atob(base64));
    }
    var user = parseJwt();
    if (user.exp * 1000 < Date.now()) {
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async function (currentUser) {
          if (currentUser) {
            try {
              let newToken = await firebase.auth().currentUser.getIdToken(true);
              setToken(newToken);
              resolve(newToken);
            } catch (err) {
              console.log(err);
              reject(err);
            }
          } else {
            resolve(null);
          }
        });
      });
    } else {
      return token;
    }
  } else {
    return null;
  }
}

export async function getMethod(url, formdata) {
  const token = await tokenValidator();
  if (token) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    // myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    if (formdata) {
      requestOptions = { ...requestOptions, body: formdata };
    }
    try {
      const response = await fetch(BASE_URL + url, requestOptions);
      const data = await response.json();
      if (data.status == true) {
        return data;
      } else {
        toastError(data.message);
        console.log("DATA MESSAGE: ", data.message);
        return response;
      }
    } catch (e) {
      toastError(e.message);
      console.log("ERROR MESSAGE: ", e.message);
      return e.message;
    }
  } else {
    return false;
  }
}

export async function postMethod(url, payload) {
  const token = await tokenValidator();
  if (token) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    // type !== "formData" && myHeaders.append("Content-Type", "application/json");

    // var raw;
    // type === "formData" ? (raw = payload) : (raw = JSON.stringify(payload));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: payload,
      redirect: "follow",
    };

    try {
      const response = await fetch(BASE_URL + url, requestOptions);
      const data = await response.json();

      if (data.status == true) {
        return data;
      } else {
        toastError(data.message);
        return response;
      }
    } catch (e) {
      toastError(e.message);
      return e.message;
    }
  } else {
    return false;
  }
}

export async function patchMethod(url, payload, type) {
  const token = await tokenValidator();
  if (token) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    type != "formData" && myHeaders.append("Content-Type", "application/json");

    var raw;
    type == "formData" ? (raw = payload) : (raw = JSON.stringify(payload));

    raw = JSON.stringify(payload);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const response = await fetch(BASE_URL + url, requestOptions);
      const data = await response.json();
      if (data.status == true) {
        return data;
      } else {
        //   toastError(data.message);
        return response;
      }
    } catch (e) {
      // toastError(e.message);
      return e.message;
    }
  } else {
    return false;
  }
}

export async function patchFormMethod(url, formdata) {
  const token = await tokenValidator();

  if (token) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    try {
      const response = await fetch(BASE_URL + url, requestOptions);
      const data = await response.json();
      if (data.status == true) {
        return data;
      } else {
        //   toastError(data.message);
        return response;
      }
    } catch (e) {
      // toastError(e.message);
      return e.message;
    }
  } else {
    return false;
  }
}
export async function postFormMethod(url, formdata) {
  const token = await tokenValidator();

  if (token) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    try {
      const response = await fetch(BASE_URL + url, requestOptions);
      const data = await response.json();
      if (data.status == true) {
        return data;
      } else {
        //   toastError(data.message);
        return response;
      }
    } catch (e) {
      // toastError(e.message);
      return e.message;
    }
  } else {
    return false;
  }
}

export async function deleteMethod(url) {
  const token = await tokenValidator();
  if (token) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    try {
      const response = await fetch(BASE_URL + url, requestOptions);
      const data = await response.json();
      if (data.status == true) {
        return data;
      } else {
        //   toastError(data.message);
        return response;
      }
    } catch (e) {
      // toastError(e.message);
      return e.message;
    }
  } else {
    return false;
  }
}

// unauth methods
export async function unSecureGetMethod(url) {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(BASE_URL + url, requestOptions);
      const data = await response.json();
      if (data.status == true) {
        return data;
      } else {
        toastError(data.message);
        return response;
      }
    } catch (e) {
      toastError(e.message);
      return e.message;
    }
}

export async function unSecurePostMethod(url, payload) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(payload)
    console.log(raw)

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(BASE_URL + url, requestOptions);
      const data = await response.json();
      console.log(data)
      if (data.status == true) {
        return data;
      } else {
        toastError(data.message);
        return response;
      }
    } catch (e) {
      toastError(e.message);
      return e.message;
    }
}