import React, { useEffect, useRef } from "react";
import ReactPlayer from "react-player";

function useOutsideAlerter(ref, handler) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handler]);
}

function VideoModal({ url, closeHandler }) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, closeHandler);
  return (
    <div className="col-12 d-flex d-align-center d-justify-center">
      <div style={{width:"100%"}} ref={wrapperRef}>
        <ReactPlayer width="100%" controls playing url={url} />
      </div>
    </div>
  );
}

export default VideoModal;
