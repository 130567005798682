function Awards({ brandImage, brandName, achievement }) {
  return (
    <div
      className="bg-white rounded-8"
      style={{ padding: "16.372px 62px 23.628px 50px" }}
    >
      <div className="d-flex d-flex-column d-align-center d-justify-center gap-1 m-auto">
          <img
            src={brandImage ? brandImage : "/assets/Image-Not-Found.png"}
            style={{ width: "90px", height: "90px", objectFit:"contain"}}
          />
        <div className="d-flex d-flex-column d-align-center m-auto">
          <span className="h4 f-600 text-center">{brandName}</span>
          <span className="h6 text-center">{achievement}</span>
        </div>
      </div>
    </div>
  );
}

export default Awards;
