import React, { useEffect, useState } from 'react'

//import components
import News from '../general/cards/publicProfile/News'
import { getMethod } from '../../utils/apiMethod';
import { toastError } from '../../utils/Toasters';
import { useContext } from 'react';
import { StateContext } from '../Context/StateContext';

function NewsCategory({companyId}) {
    const { setLoading } = useContext(StateContext);
    const [newsData, setNewsData] = useState([])

    useEffect
    (() => {
        setLoading(true);
        getMethod(`users/getCompanyNews/${companyId}`)
          .then((res) => {
            if (res?.status) {
               
    
                    console.log("Response News : ", res.data?.results)
                    setNewsData([...res.data?.results])
                  
            }
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false);
            toastError(error.message);
            console.log("error.message: ", error.message);
          });
      }, []);
  return (
    <div className="d-flex d-flex-column bg-light-blue rounded-16 p-6 gap-2">
    {/* Heading text */}
    <div>
      <h1 className="text-blue font-20">News</h1>
    </div>

   {/* Tiles */}
     <div className="d-flex d-flex-column gap-3 pb-5 mb-5">

         

             {
                newsData.map((item, index)=>{

                    return (
                      <div key={index}>
                        <News
                          thumbnail={item?.image}
                          heading={item?.title}
                          description={item?.description}
                          publishDate={item?.publishingDate}
                          url={item?.url}
                        />
                      </div>
                    );

                })
             }

     </div>
   
  </div>
  )
}

export default NewsCategory
