import { useEffect, useState } from 'react';
import { generateToken } from './firebaseConfigDB';

const useFcmToken = () => {
  const [token, setToken] = useState('');
  const [notificationPermissionStatus, setNotificationPermissionStatus] = useState('');
  
  useEffect(() => {
    const retrieveToken = async () => {
      try {
        const token = await generateToken()
        if(token){
          setToken(token)
          setNotificationPermissionStatus("granted")
        }
      } catch (error) {
        console.log('An error occurred while retrieving token:', error);
      }
    };

    retrieveToken();
  }, []);

  return { fcmToken: token, notificationPermissionStatus };
};

export default useFcmToken;