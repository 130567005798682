import React, { useContext, useEffect, useState } from 'react'
import { StateContext } from '../Context/StateContext';
import { getMethod } from '../../utils/apiMethod';
import { toastError } from '../../utils/Toasters';
import { SocialIcon } from 'react-social-icons';
import styles from "../../styles/modular css/Sources.module.css"

function Sources({companyId}) {

  
    const {setLoading} = useContext(StateContext);

    
    const [source, setSource] = useState({});
    const [socialLinks, setSocialLinks] = useState([]);


    useEffect(() => {
        setLoading(true);
        getMethod(`users/getCompanySource/${companyId}`)
          .then((res) => {
            setLoading(false);
            if (res?.status) {
                console.log(res?.data,"sources");
                setSource(res?.data?.source);
                setSocialLinks(res?.data?.socialLinks)
            }
          })
          .catch((error) => {
            setLoading(false);
            toastError(error.message);
            console.log("error.message: ", error.message);
          });
      }, []);
    
    
  return (
    <div className="bg-light-green p-10">
      <div className="d-flex d-flex-column gap-3 mt-5 mb-5">
        {/* heading */}
        <div className="font-48 f-700 l-54 text-blue">Sources and Social:</div>

        {/* links */}
        <div className={`${styles.sourceGrid} d-flex d-justify-space-between`}>
          <div className="d-flex d-flex-column">
            <div className="text-blue h3 f-600 l-39">Website</div>
            <a
              href={source?.website}
              target="_blank"
              className="word-break text-blue h4 f-400 l-30"
            >
              {source?.website}
            </a>
          </div>
          <div className="d-flex d-flex-column">
            <div className="text-blue h3 f-600 l-39">Career</div>
            <a
              href={source?.careersLink}
              target="_blank"
              className="word-break text-blue h4 f-400 l-30"
            >
              {source?.careersLink}
            </a>
          </div>
          <div className="d-flex d-flex-column">
            <div className="text-blue h3 f-600 l-39">Event Pages</div>
            <a
              href={source?.eventPageLink}
              target="_blank"
              className="word-break text-blue h4 f-400 l-30"
            >
              {source?.eventPageLink}
            </a>
          </div>
          <div className="d-flex d-flex-column">
            <div className="text-blue h3 f-600 l-39">Customer Portal</div>
            <a
              href={source?.customerPortalLink}
              target="_blank"
              className="word-break text-blue h4 f-400 l-30"
            >
              {source?.customerPortalLink}
            </a>
          </div>
        </div>
        {/* Social icons */}
        <div className="d-flex gap-2">
          {socialLinks?.map((socialLink, index) => {
            if (socialLink && socialLink.link) {
              return <SocialIcon key={index} url={socialLink.link} />;
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
}

export default Sources
