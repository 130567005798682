import React from "react";
import styles from "../../../styles/modular css/ModalWrapper.module.css";

function ModalWrapper(props) {
  return (
    <div className={`${styles["modal-container"]}`}>
      <div
        className={`col-12 d-flex d-justify-center ${styles["modal-verify"]}`}
      >
        <div className={`col-11 col-md-9 col-lg-7 col-xl-6 col-xxl-6`}>{props.children}</div>
      </div>
    </div>
  );
}

export default ModalWrapper;
