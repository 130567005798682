import cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
const encryptionKey = "@GreeNmusE!moDeraTor#enc$keY";

export const getToken = () => {
  const cookie = cookies.get('auth');
  if (!cookie) {
    return null;
  }
  return (cookie);
};

export const removeToken = () => cookies.remove('auth');

export const setToken = user => {
  cookies.set('auth', user, {
    expires: 2 / 24
  });
};

export const getUserId = () => {
  const cookie = cookies.get('Id');
  if (!cookie) {
    return null;
  }
  return (cookie);
}
export const removeUserId = () => cookies.remove('Id');


export const getUser = () => {
  const cookie = cookies.get('user');
  if (!cookie) {
    return null;
  }
  return (cookie);
};

export const setUser = user => {
  const plainText = JSON.stringify(user);
  const encrypted = CryptoJS.AES.encrypt(plainText, encryptionKey).toString();
  cookies.set('user', encrypted, {
    expires: 2 / 24
  });
};

export const removeUser = () => cookies.remove('user');

export const setOnBoardCookie = (token) => {
  cookies.set("futrUserOnboarding", token)
}

// export const setCompanyCookie = (details) => {
//   // console.log("Start here: ", details)
//   if(details.company){

//     cookies.set("firebaseUid", details?.company?.firebaseUid)
//     cookies.set("firebaseSignInProvider", details?.company?.firebaseSignInProvider)
//     cookies.set("email", details?.company?.email)
//     cookies.set("addresses", details?.company?.addresses)
//     cookies.set("id", details?.company?.id)
//     cookies.set("isBlocked", details?.company?.isBlocked)
//     cookies.set("isDeleted", details?.company?.isDeleted)
//     // cookies.set("isEmailVerified", details?.company?.isEmailVerified)
//     cookies.set("companyName", details?.company?.name)
//     cookies.set("ownerName", details?.company?.ownerName)
//     cookies.set("phone", details?.company?.phone)
//     cookies.set("description", details?.company?.description)
//     cookies.set("profileCompletePercent", details?.company?.profileCompletePercent)
//     cookies.set("thumbnailKey", details?.company?.thumbnailKey)
//     cookies.set("thumbnailUrl", details?.company?.thumbnailUrl)

//   }
//   else{

//     cookies.set("firebaseUid", details?.firebaseUid)
//     cookies.set("firebaseSignInProvider", details?.firebaseSignInProvider)
//     cookies.set("email", details?.email)
//     cookies.set("addresses", details?.addresses)
//     cookies.set("id", details?.id)
//     cookies.set("isBlocked", details?.isBlocked)
//     cookies.set("isDeleted", details?.isDeleted)
//     // cookies.set("isEmailVerified", details?.isEmailVerified)
//     cookies.set("companyName", details?.name)
//     cookies.set("ownerName", details?.ownerName)
//     cookies.set("phone", details?.phone)
//     cookies.set("description", details?.description)
//     cookies.set("profileCompletePercent", details?.profileCompletePercent)
//     cookies.set("thumbnailKey", details?.thumbnailKey)
//     cookies.set("thumbnailUrl", details?.thumbnailUrl)

//   }
 
   

// }

// export const getCompanyCookie = (cookieName) => {
//   const cookie = cookies.get(cookieName);
//   if (!cookie) {
//     return null;
//   }
//   return (cookie);
// }

// export const removeCompanyCookie = () => {

//   cookies.remove('user');

//   cookies.remove("firebaseUid")
//   cookies.remove("firebaseSignInProvider")
//   cookies.remove("email")
//   cookies.remove("hqAddress")
//   cookies.remove("hqCity")
//   cookies.remove("hqState")
//   cookies.remove("hqZipCode")
//   cookies.remove("id")
//   cookies.remove("isBlocked")
//   cookies.remove("isDeleted")
//   cookies.remove("companyName")
//   cookies.remove("ownerName")
//   cookies.remove("phone")
//   cookies.remove("description")
//   cookies.remove("profileCompletePercent")
//   cookies.remove("thumbnailKey")
//   cookies.remove("thumbnailUrl")

// }

export const removeOnBoardCookie = () => cookies.remove("futrUserOnboarding");

export const setUserCookie = (details) => {
  if(details.user){

    cookies.set("firebaseUid", details?.user?.firebaseUid)
    cookies.set("firebaseSignInProvider", details?.user?.firebaseSignInProvider)
    cookies.set("email", details?.user?.email)
    cookies.set("id", details?.user?.id)
    cookies.set("userName", details?.user?.name)

    cookies.set("phone", details?.user?.phone)
    cookies.set("bio", details?.user?.bio)
    cookies.set("profilePicKey", details?.user?.profilePicKey)
    cookies.set("profilePicUrl", details?.user?.profilePicUrl)

  }
  else{

    cookies.set("firebaseUid", details?.firebaseUid)
    cookies.set("firebaseSignInProvider", details?.firebaseSignInProvider)
    cookies.set("email", details?.email)
    cookies.set("id", details?.id)
    cookies.set("userName", details?.name)
    
    cookies.set("phone", details?.phone)
    cookies.set("bio", details?.bio)
    cookies.set("profilePicKey", details?.profilePicKey)
    cookies.set("profilePicUrl", details?.profilePicUrl)

  }
}

export const getUserCookie = (cookieName) => {
  const cookie = cookies.get(cookieName);
  if (!cookie) {
    return null;
  }
  return (cookie);
}

export const removeUserCookie = () => {

  cookies.remove('user');
  cookies.remove("firebaseUid")
  cookies.remove("firebaseSignInProvider")
  cookies.remove("email")
  cookies.remove("id")

  cookies.remove("userName")
  cookies.remove("phone")
  cookies.remove("bio")
  cookies.remove("profilePicKey")
  cookies.remove("profilePicUrl")

}