import { useState } from "react";
import ReadMore from "../../ReadMoreNew";
import styles from "../../../../styles/modular css/News.module.css"

function News({ thumbnail, heading, description, publishDate, url }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <a href={url} target="_blank">
      <div className={`${styles.card} d-flex d-align-start gap-3 border-bottom-dark pb-5`}>
        <div className={`${styles.imageWrapper}`}>
          <img
            width="100%"
            height={134}
            src={thumbnail ? thumbnail : "/assets/NewsImage.png"}
            className="object-cover rounded-8"
          />
        </div>
        <div className="d-flex d-flex-column">
          <p className="m-0 font-120 f-600 text-grey-15 line-clamp-2">
            {heading}
          </p>
          <div>
            <div className="h5" dangerouslySetInnerHTML={{ __html: description }}></div>
            <button
              // onClick={toggleReadMore}
              className="border-none bg-none f-600 font-16 text-left p-0"
            >
              Read More
            </button>
          </div>

          <div className="font-16 text-grey-16">
            Published on: {new Date(publishDate).toDateString()}
          </div>
        </div>
      </div>
    </a>
  );
}

export default News;
