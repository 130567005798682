import React, { useState } from "react";
const ScoreSlider = ({ id, onSliderChange, initialValue }) => {
  const [sliderValue, setSliderValue] = useState(
    initialValue ? initialValue : 0
  );

  const handleSliderChange = (event) => {
    const value = event.target.value;
    setSliderValue(value);

    // Pass the slider id and value to the parent component
    onSliderChange(id, value);
  };

  const sliderStyle = {
    background: `linear-gradient(to right, #1CBAAB 0%, #1CBAAB ${
      sliderValue * 10
    }%, #d3d3d3 ${sliderValue * 10}%, #d3d3d3 100%)`,
  };
  return (
    <div className="d-flex d-flex-column gap-1">
      <div className="font-16">0</div>

      <div className="score-container d-flex gap-1">
        <input
          type="range"
          min="0"
          max="10"
          value={sliderValue}
          className="score-dragger"
          onChange={(e) => {
            handleSliderChange(e);
          }}
          style={sliderStyle}
        />
        <div className="font-16">10</div>
      </div>

      <div className="d-flex d-justify-space-between">
        <div className="font-16">{sliderValue == "0" && "No Confidence"}</div>
        <div className="font-16">
          {sliderValue == "10" && "Extremely Confident"}
        </div>
      </div>
    </div>
  );
};

export default ScoreSlider;
