// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
// import { getFirestore } from "firebase/firestore";
import Cookies from "js-cookie";
import { postMethod } from "../utils/apiMethod";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBmPQosjU_3qSYNzRcFgONeKf02FokMUwI",
  authDomain: "futr-dev01.firebaseapp.com",
  databaseURL: "https://futr-dev01-default-rtdb.firebaseio.com",
  projectId: "futr-dev01",
  storageBucket: "futr-dev01.appspot.com",
  messagingSenderId: "43321799474",
  appId: "1:43321799474:web:9175ce005a5799f0e05d2b",
  measurementId: "G-FFQPYHP6J2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// export const database = getFirestore();

export const generateToken = async () => {
    try {
      if (typeof window !== 'undefined' && 'Notification' in window) {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          const messaging = getMessaging(app);
          if(Cookies.get("fcm") == undefined || Cookies.get("fcm") == 'undefined'){
            const token = await getToken(messaging, {
              vapidKey: "BCY75Us33TKwQ4V4zTxdbY8CoLHWCB6z2Y7BdcdhOiLesTazABNWrwozQl3Zux_U5vuOm-O2R50I1fl2kezuFzI",
            });
            let onBoardingToken = Cookies.get("auth");
            if (token &&  onBoardingToken) {
              //Subscribe to FCM messages
              var raw = JSON.stringify({
                fcmtoken: token
              })
              const response = await postMethod("notification/subscribeUser",raw);
              if(response?.status){
                Cookies.set("fcm",token)
                return token;
              }
            } else {
              console.log("Failed to retrieve FCM token");
            }
          }else{
            return Cookies.get("fcm")
          }
        } else {
          console.log("Notification permission denied by user");
        }
      } else {
        console.log("Notifications not supported on this platform");
      }
    } catch (error) {
      console.error("Error retrieving FCM token:", error);
      throw error;
    }
};