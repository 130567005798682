import { BarLoader } from "react-spinners";

export default function FirstLoader() {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: "0",
        left: "0",
        backgroundColor: "white",
    
        zIndex: "9999",
      }}
      className={`d-flex d-justify-center d-align-center`}
    >
      <div className="d-flex-col d-align-center d-justify-center col-4 col-md-3 col-xl-2">
        <div className="">
          <img className="w-full h-full" src="/assets/FutrLogo.png" />
        </div>

        <BarLoader height={10} width={"100%"} color="#1CBAAB"></BarLoader>
      </div>
    </div>
  );
}
