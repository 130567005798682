import React from 'react'
import Header from '../general/Header';
import Footer from '../general/Footer';
import { ToastContainer } from 'react-toastify';

function ClaimProfileWrapper(props) {
  return (
    <div>
      <Header />
      <div className="claim-portal-wrapper mt-170">
        <div
          style={{
            background:
              "linear-gradient(rgb(245, 245, 245) 0%, rgb(238, 249, 251) 94.91%)",
          }}
          className="container col-7 d-flex d-align-center d-justify-center p-8 rounded-8"
        >
          {props.children}
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default ClaimProfileWrapper