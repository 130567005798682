import React from "react";
import { useState } from "react";
import styles from "../../styles/modular css/CompanyDropdown.module.css"

function CompanyDropdown({ data, handler }) {
  const [open, setOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState("");
  const searchTermHandler = e => {
    setOpen(true)
    setSearchTerm(e.target.value)
  }
  return (
    <div className="p-relative">
      <div onClick={()=>setOpen(prev => !prev)} className="d-flex d-align-center border rounded-8 pl-4 pt-3 pb-3 pr-4 bg-white">
        <input
          required
          value={searchTerm}
          onChange={searchTermHandler}
          placeholder="Search for company"
          className="col-12 border-none"
        />
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6L8 10L12 6"
            stroke="#1E1E1E"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      {open&&<div
        className={`${styles.popup} col-12 p-absolute d-flex d-flex-column bg-white box-shadow rounded-8 p-1`}
      >
        {data &&
          data
            ?.filter((val) => {
              if (searchTerm == "") {
                return val;
              } else if (
                val?.name &&
                val?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
              ) {
                return val;
              }
            })
            ?.map((item, index) => (
              <span
                onClick={()=>{
                  setOpen(false);
                  setSearchTerm(item?.name)
                  handler(item)
                }}
                key={item?.id}
                className="dropdown-items h5 f-400 l-20 text-black pl-3 pr-3 pt-2 pb-2"
              >
                {item?.name}
              </span>
            ))}
      </div>}
    </div>
  );
}

export default CompanyDropdown;
