import {React, useEffect, useState} from 'react'
import ContentVideoCard from '../../general/cards/publicProfile/ContentVideoCard';
import { getMethod } from '../../../utils/apiMethod';
import { toastError } from '../../../utils/Toasters';
import Loader from '../../general/Loader';

function TopContent({companyId}) {

    const [loading, setLoading] = useState(false);

    const [featured, setFeatured] = useState({});
    const [firstCall, setFirstCall] = useState({});
    const [demo, setDemo] = useState({});
  
    useEffect(() => {
        setLoading(true)
        getMethod(`users/getCompanyTopContent/${companyId}`)
          .then((res) => {
            setLoading(false)
            if (res?.status) {
            
                    setDemo(...res.data?.filter((video)=> video.markedVideo == "demoVideo"));
                    setFeatured(...res.data?.filter((video)=> video.markedVideo == "featuredVideo"));
                    setFirstCall(...res.data?.filter((video)=> video.markedVideo == "videoCall"));
          
            }
          })
          .catch((error) => {
            setLoading(false)
            toastError(error.message);
            console.log("error.message: ", error.message);
          });
    
    }, [])
  return (
    <>
      {
        loading ? <Loader/> : (

            <div className="d-flex gap-3">

            <div className="col-4">
        
                <ContentVideoCard video={featured?.videoLink} title={featured?.title}/>
        
            </div>
            <div className="col-4">
        
                <ContentVideoCard  video={demo?.videoLink} title={demo?.title}/>
        
            </div>
            <div className="col-4">
        
                <ContentVideoCard  video={firstCall?.videoLink} title={firstCall?.title}/>
        
            </div>
        
        </div>

        )
    }
    </>
  

  )
}

export default TopContent
