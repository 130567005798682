import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { postMethod } from "../utils/apiMethod";
import { toastSuccess } from "../utils/Toasters";

// Helper function to reorder items in the list
const reorder = (list, startIndex, endIndex) => {
  const [removed] = list.splice(startIndex, 1);
  list.splice(endIndex, 0, removed);
  return list;
};

// Helper function to split the list into rows of fixed size
const splitIntoRows = (list, rowSize) => {
  const rows = [];
  for (let i = 0; i < list.length; i += rowSize) {
    rows.push(list.slice(i, i + rowSize));
  }
  return rows;
};

let initialCards = [
  { id: "1", content: "Card 1" },
  { id: "2", content: "Card 2" },
  { id: "3", content: "Card 3" },
  { id: "4", content: "Card 4" },
  { id: "5", content: "Card 5" },
  { id: "6", content: "Card 6" },
];

function CardRanking() {
  const [cards, setCards] = useState(initialCards);

  const rankCard = async (productId, rank) => {
    var raw = JSON.stringify({
      productId: productId,
      rank: rank + 1,
    });
    let response = await postMethod(`product/rankProduct`, raw);
    if (response?.status === true) {
      toastSuccess("List updated successfully!");
    }
  };

  const handleOnDragEnd = async (result) => {
    const { source, destination } = result;

    // Dropped outside the list
    if (!destination) return;

    // Reorder the flat list of cards
    const reorderedCards = reorder(
      Array.from(cards),
      source.index,
      destination.index
    );

    // Set the updated card positions
    setCards(reorderedCards);

    // Optionally, update the backend with the new position
    // await rankCard(reorderedCards[destination.index]?.id, destination.index);
  };

  // Split the flat list into rows of 3
  const rows = splitIntoRows(cards, 3);

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      {rows.map((row, rowIndex) => (
        <Droppable
          droppableId={`${rowIndex}`}
          direction="horizontal"
          key={rowIndex}
        >
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)", // Ensure there are 3 cards per row
                gap: "8px",
                padding: "12px",
                width: "100%",
                marginBottom: "20px",
                minHeight: "100px", // Ensure there's a minimum height
              }}
            >
              {row.map((product, index) => (
                <Draggable
                  key={product.id}
                  draggableId={product.id}
                  index={rowIndex * 3 + index} // Correct index for the flat list
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        userSelect: "none",
                        // background: snapshot.isDragging ? "#e3e3e3" : "#ffffff",
                        background:"#e3e3e3",
                        borderRadius: "4px",
                        boxShadow: snapshot.isDragging
                          ? "0 2px 4px rgba(0,0,0,0.2)"
                          : "none",
                        minHeight: "100px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div>{product.content}</div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ))}
    </DragDropContext>
  );
}

export default CardRanking;
