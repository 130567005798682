import React, { useEffect } from 'react'

import { getToken } from './auth/cookies'
import { useNavigate } from 'react-router-dom';

function ProtectedRoutes({component}) {

    const token = getToken('auth');
    const navigate = useNavigate();

    useEffect(() => {

        if(!token) {
            navigate("/");
        }
        
      }, [])

  return (
    <div>
      {component}
    </div>
  )
}

export default ProtectedRoutes
