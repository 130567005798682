function Executive({ imageUrl, name, title, linkedinUrl }) {
  return (

      <div className="d-flex d-justify-space-between rounded-12 bg-white p-5">
        {/* Person details */}

        <div className="col-10 d-flex d-justify-space-between d-align-center">
          {/* Profile Image */}
          <div className="col-3 rounded-50 d-flex d-justify-center d-align-center">
            <img
              src={imageUrl ? imageUrl : "/assets/Image-Not-Found.png"}
              className="rounded-50 image-cover m-auto"
              style={{ width: "69px", height: "68px" }}
            />
          </div>

          {/* Person name and work */}
          <div className="col-8 d-flex d-flex-column">
            <div className="font-20 f-600 card-text-wrap">
              {name ? name : ""}
            </div>
            <div className="font-14 f-400 card-text-wrap">
              {title ? title : ""}
            </div>
          </div>
        </div>

        {/* linkedin */}
        <a href={linkedinUrl} target="_blank">
          <div
            className="d-flex d-justify-center d-align-center rounded-50 bg-grey-13 p-3"
            style={{ width: "40px", height: "40px" }}
          >
            <img
              src="/assets/linkedInLogo.svg"
              className=""
              style={{ width: "24px", height: "25px" }}
            />
          </div>
        </a>
      </div>
  );
}
  
  export default Executive;
  