import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const ImageUploader = ({fetchImage}) => {
    const [images, setImages] = useState([]);

    const onDrop = useCallback((acceptedFiles) => {
        const newImages = acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }));
        setImages(prevImages => [...prevImages, ...newImages]);
        fetchImage([...newImages])
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: true,
    });
    console.log("Images: ", images )
    return (
        <div className="col-12">
            <div
                {...getRootProps()}
                className={`bg-white rounded-8 col-12 position-relative 
                border-dashed border-2 p-4 text-center
                 cursor-pointer ${isDragActive ? 'border-blue-500' : 'border-gray-300'}`}
            >
                <input {...getInputProps()} className="hidden" />
                {images.length === 0 ? (
                    <p className="text-grey-3">Upload / Drag Image Here</p>
                ) : (
                    <div className="mt-4 d-grid grid-col-12 gap-2">
                        {images.map((image, index) => (
                            <div key={index} className="p-relative">
                                <img 
                                    src={image.preview} 
                                    alt={`Preview ${index}`} 
                                 
                                    className="w-full h-full object-cover rounded-10"
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageUploader;

// import React, { useCallback, useState } from 'react';
// import { useDropzone } from 'react-dropzone';

// const ImageUploader = ({fetchImage}) => {
//     const [image, setImage] = useState(null);

//     const onDrop = useCallback((acceptedFiles) => {
//         const newImage = acceptedFiles[0];
//         newImage.preview = URL.createObjectURL(newImage);
//         setImage(newImage);
//         fetchImage(newImage)
//     }, []);

//     const { getRootProps, getInputProps, isDragActive } = useDropzone({
//         onDrop,
//         accept: 'image/*',
//         multiple: false,
//     });

//     console.log("Image: ", image);

//     return (
//         <div className="col-12">
//             <div
//                 {...getRootProps()}
//                 className={`bg-white rounded-8 col-12 position-relative 
//                 border-dashed border-2 p-4 text-center
//                 cursor-pointer ${isDragActive ? 'border-blue-500' : 'border-gray-300'}`}
//             >
//                 <input {...getInputProps()} className="hidden" />
//                 {!image ? (
//                     <p className="text-grey-3">Upload / Drag Image Here</p>
//                 ) : (
//                     <div className="mt-4 d-grid grid-cols-12">
//                         <div className="p-relative">
//                             <img 
//                                 src={image.preview} 
//                                 alt="Preview" 
//                                 width={120}
//                                 height={120}
//                                 className="object-cover rounded-10"
//                             />
//                         </div>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default ImageUploader;
