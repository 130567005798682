import React, { useContext, useEffect, useState } from 'react'
import { Router, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { setUserCookie, setOnBoardCookie, removeOnBoardCookie } from '../auth/cookies';
import { StateContext } from './Context/StateContext';
import { toastError, toastSuccess } from '../utils/Toasters';
import { LoginQuery } from '../utils/auth';
import { setToken } from '../auth/cookies';
import EmailVerificationModal from './general/Modals/EmailVerificationModal';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
function LinkedInAuth() {
  const [showEmailVerificationModal, setShowEmailVerificationModal] =
    useState(false);
  const navigate = useNavigate()

    const query = useQuery();

    const {setLoading} = useContext(StateContext);

    const loginHandler = async (token) => {
      setLoading(true)
      try{
         let response = await LoginQuery()
              const res =  response.json();
              console.log("RESPONSE IN LOG : ", res)
              if (res.status) {
                setToken(token)
                setOnBoardCookie(token);
                setUserCookie(res)
                toastSuccess('Logged In successfully!')
                setLoading(false)
                navigate('/dashboard')
              } else {
                toastError(response.message);
                setLoading(false);
                // signOut()
              }
          
      }catch(e){
        toastError(e.message)
          console.log(e)
      }
    }

    async function handleRegisterApiCall(token) {

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + token
      );

      var raw = JSON.stringify({
        // name: fullName,
        // email: email,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const url = "https://api.dev.futrconnect.com/v1/";

      fetch(url + "auth/registerUser", requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result?.status) {
            removeOnBoardCookie();
            setOnBoardCookie(
              token
            );
            setUserCookie(result);

            toastSuccess("Registration successful");

            // userCredential.user.sendEmailVerification();
            setShowEmailVerificationModal(true);
            setLoading(false);
          } else {
            toastError(result.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toastError(error?.message);
        });
    }

    useEffect(() => {
        if(query.get("status") === true || query.get("status") === "true" ){
            const linkedInDetails = {
                idToken : query.get("idToken"),
                nextStep : query.get("nextStep"),
            }

             if (
               query.get("state") === "user" &&
               query.get("nextStep") === "signup"
             ) {
              setOnBoardCookie(linkedInDetails.idToken);

             }
            
            if(query.get("state") == "user" && query.get("nextStep") == "login"){
              loginHandler(linkedInDetails.idToken);
            }
            // window.close();
        }
    }, [])
    
  return (
    <div>
      Loading ...
      <ToastContainer />
      {showEmailVerificationModal && (
        <EmailVerificationModal
          onClickBackToLogin={() => navigate("/")}
          onClickClose={() => setShowEmailVerificationModal(false)}
        />
      )}
    </div>
  );
}

export default LinkedInAuth