import React, { useEffect } from 'react'
import { useNavigate } from 'react-router';
import useFirebaseAuth from '../auth/useFirebaseAuth';
import { LinkedIn } from './general/icons/Icons';

function SignInWithLinkedIn() {

  const navigate = useNavigate()

    const {signInWithCustomToken} = useFirebaseAuth();
    const BASE_URL = 'https://api.dev.futrconnect.com/v1/'

    const submitHandler = async() => {
        try{

            let response = await fetch(`${BASE_URL}auth/linkedin?type=user`) // backend API for linkedin
            let result = await response.json();
            console.log("RESULT : ", result)

            window.open(result?.linkedInAuthUrl);
        } catch(e){

        }
    }

    const handleLinkedInCallback = async (code) => {
        try {
          const response = await fetch(`${BASE_URL}/auth/linkedInCallback?code=${code}`);
          const { firebaseToken } = response.data;
          console.log("FIREBASE TOKEN : ", firebaseToken)
          const authUser = await signInWithCustomToken(firebaseToken);
          // set cookies

          console.log('User signed in with LinkedIn!');
        } catch (error) {
          console.error('Error during LinkedIn login:', error);
        }
      };

    useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      handleLinkedInCallback(code);
    }
     
    }, [])
    

  return (
    <div
      className="input-field p-2 font-20 text-light-grey2 text-center d-flex d-justify-center gap-1 hover-effect d-flex d-align-center"
      onClick={submitHandler}
    >
      <LinkedIn />
      Sign In with LinkedIn
    </div>
  );
}

export default SignInWithLinkedIn