import {React, useState, useContext, useEffect} from 'react'
import { getMethod, postFormMethod } from '../../utils/apiMethod';
import { ToastContainer } from 'react-toastify'
import { toastError, toastSuccess } from '../../utils/Toasters';
import { StateContext } from '../Context/StateContext';
import ImageUploader from '../general/feedback/ImageUploader';
import cookies from 'js-cookie';

function Feedback({companyId}) {

    const {setLoading} = useContext(StateContext);

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [title, setTitle] = useState("");
    const [openToContact, setOpenToContact] = useState(false);
    const [description, setDescription] = useState("");
    const [image, setImage] = useState([]);

     // user details variables and function
  const [userDetails, setUserDetails] = useState([]);

  const fetchUserDetails = () => {
    setLoading(true);
    getMethod(`users/getMyUserDetails`)
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          setUserDetails(res?.user);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });

  }

  const submitHandler = (e) => {
    setLoading(true)
    
    var formdata = new FormData();
    
    // if (firstName !== null) {
    //   formdata.append("firstName", firstName);
    // }
    // if (lastName !== null) {
    //   formdata.append("lastName", lastName);
    // }
    // if (companyName !== null) {
    //   formdata.append("companyName", companyName);
    // }
    if (title !== null) {
      formdata.append("title", title);
    }
    if (description !== null) {
      formdata.append("description", description);
    }
    // if ( email !== null ||
    //     email !== undefined ||
    //     email.trim() !== "" ||
    //     emailPattern.test(email)) {
    //   formdata.append("email", email);
    // }
    // if ( image !== null &&
    //     image !== undefined) {
    //   formdata.append("image", image);
    // }
    formdata.append("openToContact", openToContact);
   
    postFormMethod(`users/companyFeedback/${companyId}`, formdata)
      .then((res) => {
        setLoading(false);
        if(res.status ){

          if(image.length > 0) {

            console.log("OKAY")

            let imagesFormData = new FormData();
            image.forEach((file) => {
    
              imagesFormData.append("image", file)
    
            })
    
    
            postFormMethod(`users/addFeedbackImages/${res?.data?.id}`, imagesFormData)
            .then((res) => {
              setLoading(false);
                    
  
          console.log("res: ", res)
          toastSuccess("Feedback added successfully!");
            
            })
            .catch((error) => {
              setLoading(false);
              toastError(error.message);
              console.log("error.message: ", error.message);
            });
  
            
          }
          else {
            console.log("res: ", res)
            toastSuccess("Feedback added !");
          }

         
        }

       
      
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  };

  console.log("FEEDBACK images: ", image)

  useEffect(() => {
   fetchUserDetails()
  }, [])
  

  return (
    <div className="d-flex d-flex-column bg-light-blue rounded-16 p-6 gap-2">
      {/* Heading text */}
      <div>
        <h1 className="text-blue font-20">Feedback</h1>
        <p className="font-12 f-400 text-grey-14">
          Feedback is critical to a company&apos;s success, please provide feedback,
          good or bad. In general, we want to hear from you, please engage with
          us here.
        </p>
      </div>

      {/* Form */}
      <div className="d-flex d-flex-column gap-2">
        <div className="d-flex gap-2">
          {/* <div className="bg-white rounded-8 col-4 d-flex">
            <input
              className="input-field-white text-grey-19 text-capitalize border-none col-12"
              placeholder="First Name"
              value={userDetails?.name}
              disabled={true}
            />
          </div> */}
          {/* <div className="bg-white rounded-8 col-3">

                <input className="input-field-white border-none" placeholder="Last Name" 
                value={lastName} onChange={e => setLastName(e.target.value)}/>

            </div> */}
          {/* <div className="bg-white rounded-8 col-4">
            <input
              className="input-field-white text-grey-19 border-none"
              placeholder="Company Name"
              value={
                userDetails?.companyName
                  ? userDetails?.companyName
                  : "No Company added"
              }
              disabled={true}
            />
          </div> */}
          {/* <div className="bg-white rounded-8 col-4">
            <input
              type="email"
              className="input-field-white text-grey-19 border-none"
              placeholder="Email"
              value={userDetails?.email}
              disabled={true}
            />
          </div> */}
        </div>
        <div className="bg-white rounded-8 col-12">
          <input
            className="input-field-white border-none"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="bg-white rounded-8 col-12">
          <textarea
            className="input-field-white border-none col-12"
            placeholder="Comments"
            style={{ resize: "none" }}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        {/* <div className="bg-white rounded-8 col-12 position-relative">

                <textarea className="text-area input-field-white border-none col-12" placeholder="Upload / Drag Image Here"/>

        </div> */}
        <ImageUploader fetchImage={setImage} />
        <div className="d-flex d-align-center">
          <input
            type="checkbox"
            value={openToContact}
            onChange={(e) => setOpenToContact(e.target.checked)}
            className="checkbox-input"
          />

          <p className="font-12 f-400 text-grey-14">
            Please click here to confirm you are OK with us contacting you
            regarding your feedback
          </p>
        </div>
        <div>
          <button
            className="bg-sea-green text-white p-4 rounded-5 border-none col-2"
            onClick={submitHandler}
          >
            Send
          </button>
        </div>
      </div>
      {/* <ToastContainer/> */}
    </div>
  );
}

export default Feedback
