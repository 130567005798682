// import dependencies
import { useEffect, useState } from "react";
//import files
import ConfirmationModal from "../../Modals/ConfirmationModal";
import ScoreModal from "../../Modals/ScoreModal";
import { getMethod } from "../../../../utils/apiMethod";
import { toastError, toastSuccess } from "../../../../utils/Toasters";
import { Link } from "react-router-dom";

function Product({ name, image, description, id, category, index, classes }) {
  // state variables and functions

  return (
    <div
      className={`d-flex d-flex-column rounded-8 box-shadow1 p-2 ${classes}`}
      style={{ height: "215px" }}
    >
      {/* Upper row */}

      <div className="d-flex gap-2 font-12 h-full          ">
        <div className="col-5 d-flex d-justify-center">
          <img
            src={`${image ? image : "/assets/Image-Not-Found.png"}`}
            alt="product image"
            className="rounded-8 flex-1 w-full h-full"
          />
        </div>
        <div className="d-flex d-flex-column p-1 gap-1 col-7">
          {/* Title */}
          <div className="f-700 font-20 text-capitalize">{name}</div>

          {/* Description */}
          <div
            className="text-black font-12 f-400 text-left"
            style={{ height: "60%" }}
          >
            {description !== null &&
            description !== "null" &&
            description !== undefined &&
            description.length > 0 ? (
              description?.length > 200 ? (
                description.slice(0, 180) + "..."
              ) : (
                description
              )
            ) : (
              <em>Description not yet added</em>
            )}
          </div>

          {/* Category */}
          <div
            className="ox-scroll d-flex d-align-center gap-1 o-hidden hide-scrollbar p-2"
            style={{ maxWidth: "100%" }}
          >
            {category?.map((item, index) => {
              return (
                <div
                  className="bg-light-green text-sea-green rounded-5 p-2 pl-3 pr-3 w-fit"
                  style={{ minWidth: "fit-content" }}
                >
                  <div></div>
                  <div className="font-16">
                    {item?.productTypeDefined?.name}
                  </div>
                </div>
              );
            })}
          </div>

          {/* Button */}

          <div>
            <button className="btn btn-sea-green-thin w-full">
              View Product
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;
