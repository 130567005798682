function Tiles({image, heading, content, bg, type, dollar}) {
    return (
      <div className="bg-white rounded-8 p-5" style={{ height: "auto" }}>
        <div className="d-flex gap-3">
          {/* <div className="col-3 bg-sea-green rounded-50">

                    <img src="/assets/Company.jpeg" className="rounded-50 image-cover"/>

                </div> */}

          <div className={` d-flex d-align-center`}>
            <div
              className="d-flex rounded-50 h-full w-full"
              style={{
                backgroundColor: `${bg}`,
                minWidth: "70px",
                minHeight: "70px",
                maxWidth: "70px",
                maxHeight: "70px",
              }}
            >
              <img
                src={image}
                className="rounded-50 image-cover m-auto"
                style={{ width: "36px", height: "36px" }}
              />
            </div>
          </div>

          <div className="col-9 d-flex d-flex-column">
            <div className="font-16 f-400">{heading}</div>
            <div className="text-capitalize">
              {content&&content!=undefined&&content!="undefined"?<h1
                onClick={() => {
                  heading == "Yahoo Finance Link" &&
                    window.open(content, "_blank");
                }}
                className={`${
                  heading == "Yahoo Finance Link" && "cursor-pointer"
                } f-600`}
              >
                {dollar}
                {content}
              </h1>:
              <h1
                onClick={() => {
                  heading == "Yahoo Finance Link" &&
                    window.open(content, "_blank");
                }}
                className={`${
                  heading == "Yahoo Finance Link" && "cursor-pointer"
                } f-600`}
              >
                N/A
              </h1>}
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default Tiles;
  
  