import ReactPlayer from "react-player";
import styles from "../../../../styles/modular css/Resources.module.css";
import { toastError } from "../../../../utils/Toasters";
import axios from "axios";
import { saveAs } from "file-saver";

function ResourcesCard({ type, mediaUrl, name }) {
 const downloadHandler = async () => {
   try {
     // URL of the file on S3
     const fileUrl = mediaUrl;

     // Fetch the file from the URL
     const response = await axios.get(fileUrl, {
       responseType: "blob", // Important
     });

     // Get the filename from the Content-Disposition header if available
     const contentDisposition = response.headers["content-disposition"];
     let fileName = name || "download"; // Fallback filename
     if (
       contentDisposition &&
       contentDisposition.indexOf("attachment") !== -1
     ) {
       const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
       if (fileNameMatch && fileNameMatch.length === 2) {
         fileName = fileNameMatch[1];
       }
     }

     // Save the file using file-saver
     saveAs(response.data, fileName);
   } catch (error) {
     toastError(`Error downloading the file: ${error.message}`);
   }
 };


  let fileTypes = {
    pdf: "/assets/pdfDoc.svg",
    excel: "/assets/xmlDoc.svg",
    word: "/assets/wordDoc.svg",
  };
  return (
    <div className={`${styles.wrapper} p-relative`}>
      {type == "image" ||
      type == "video" ||
      type == "pdf" ||
      type == "excel" ||
      type == "word" ? (
        <>
          {type == "image" && (
            <img
              crossorigin="anonymous"
              className="object-cover rounded-16"
              src={mediaUrl}
              alt={name}
              width={137}
              height={144}
            />
          )}
          {type == "video" && (
            <ReactPlayer url={mediaUrl} alt={name} width={137} height={144} />
          )}
          {(type == "pdf" || type == "excel" || type == "word") && (
            <div
              style={{
                width: "137px",
                height: "144px",
              }}
              className="d-flex d-align-center d-justify-center"
            >
              <img
                crossorigin="anonymous"
                width={137}
                height={144}
                src={fileTypes[type]}
                alt={name}
              />
            </div>
          )}
        </>
      ) : (
        <>
          <div
            style={{
              width: "137px",
              height: "144px",
            }}
            className="d-flex d-align-center d-justify-center"
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40.0687 15.4313L29.5687 4.93127C29.283 4.65269 28.8991 4.49777 28.5 4.50002H10.5C9.70435 4.50002 8.94129 4.81609 8.37868 5.3787C7.81607 5.94131 7.5 6.70437 7.5 7.50002V40.5C7.5 41.2957 7.81607 42.0587 8.37868 42.6213C8.94129 43.184 9.70435 43.5 10.5 43.5H37.5C38.2956 43.5 39.0587 43.184 39.6213 42.6213C40.1839 42.0587 40.5 41.2957 40.5 40.5V16.5C40.5023 16.101 40.3473 15.717 40.0687 15.4313ZM30 33H18C17.6022 33 17.2206 32.842 16.9393 32.5607C16.658 32.2794 16.5 31.8978 16.5 31.5C16.5 31.1022 16.658 30.7207 16.9393 30.4394C17.2206 30.1581 17.6022 30 18 30H30C30.3978 30 30.7794 30.1581 31.0607 30.4394C31.342 30.7207 31.5 31.1022 31.5 31.5C31.5 31.8978 31.342 32.2794 31.0607 32.5607C30.7794 32.842 30.3978 33 30 33ZM30 27H18C17.6022 27 17.2206 26.842 16.9393 26.5607C16.658 26.2794 16.5 25.8978 16.5 25.5C16.5 25.1022 16.658 24.7207 16.9393 24.4394C17.2206 24.1581 17.6022 24 18 24H30C30.3978 24 30.7794 24.1581 31.0607 24.4394C31.342 24.7207 31.5 25.1022 31.5 25.5C31.5 25.8978 31.342 26.2794 31.0607 26.5607C30.7794 26.842 30.3978 27 30 27ZM28.5 16.5V8.25002L36.75 16.5H28.5Z"
                fill="#82829B"
              />
            </svg>
          </div>
        </>
      )}
      <div
        className={`${styles.overlay} p-absolute d-flex d-align-center d-justify-center gap-2`}
      >
        <span>
          <a
            className="d-flex d-align-center d-justify-center"
            href={mediaUrl}
            target="_blank"
          >
            <svg
              width="29"
              height="30"
              viewBox="0 0 29 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8125 15.2917C11.8125 14.579 12.0956 13.8954 12.5997 13.3914C13.1037 12.8874 13.7872 12.6042 14.5 12.6042C15.2128 12.6042 15.8963 12.8874 16.4003 13.3914C16.9044 13.8954 17.1875 14.579 17.1875 15.2917C17.1875 16.0045 16.9044 16.6881 16.4003 17.1921C15.8963 17.6961 15.2128 17.9792 14.5 17.9792C13.7872 17.9792 13.1037 17.6961 12.5997 17.1921C12.0956 16.6881 11.8125 16.0045 11.8125 15.2917Z"
                fill="#1CBAAB"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.55566 15.2918C2.55566 17.2507 3.0633 17.9088 4.07858 19.2287C6.10555 21.8612 9.50494 24.8473 14.5001 24.8473C19.4953 24.8473 22.8947 21.8612 24.9216 19.2287C25.9369 17.91 26.4446 17.2495 26.4446 15.2918C26.4446 13.3329 25.9369 12.6747 24.9216 11.3549C22.8947 8.72232 19.4953 5.73621 14.5001 5.73621C9.50494 5.73621 6.10555 8.72232 4.07858 11.3549C3.0633 12.6759 2.55566 13.3341 2.55566 15.2918ZM14.5001 10.8126C13.3122 10.8126 12.1729 11.2845 11.3329 12.1245C10.4929 12.9645 10.0209 14.1038 10.0209 15.2918C10.0209 16.4797 10.4929 17.619 11.3329 18.459C12.1729 19.299 13.3122 19.7709 14.5001 19.7709C15.6881 19.7709 16.8274 19.299 17.6674 18.459C18.5074 17.619 18.9793 16.4797 18.9793 15.2918C18.9793 14.1038 18.5074 12.9645 17.6674 12.1245C16.8274 11.2845 15.6881 10.8126 14.5001 10.8126Z"
                fill="#1CBAAB"
              />
            </svg>
          </a>
        </span>
        <span onClick={downloadHandler}>
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.3959 20.6806L9.05046 14.3351L10.8272 12.4949L14.1268 15.7946V5.45142H16.665V15.7946L19.9647 12.4949L21.7414 14.3351L15.3959 20.6806ZM7.78136 25.757C7.08336 25.757 6.48561 25.5082 5.98813 25.0107C5.49064 24.5133 5.24232 23.9159 5.24317 23.2188V19.4115H7.78136V23.2188H23.0105V19.4115H25.5487V23.2188C25.5487 23.9168 25.3 24.5145 24.8025 25.012C24.305 25.5095 23.7077 25.7578 23.0105 25.757H7.78136Z"
              fill="#59CAEC"
            />
          </svg>
        </span>
      </div>
    </div>
  );
}

export default ResourcesCard;
