import { useEffect, useState } from "react";
import { getMethod } from "../../../utils/apiMethod.js";
import { toastError } from "../../../utils/Toasters.js";

function OptionsPopup({ optionsList, getSelectedOption, setLoading }) {
  const [list, setList] = useState([{ name: "All", id: "0" }, ...optionsList]);
  return (
    <div
      style={{ height: "auto", maxHeight: "200px" }}
      className="bg-white rounded-10 p-3 gap-2 text-left box-shadow2 z-1000 o-hidden oy-scroll"
    >
      {list.map((option, index) => {
        return (
          <div
            key={index}
            className="d-flex gap-1 p-2 d-flex-shrink-0 cursor-pointer"
            onClick={() => getSelectedOption(option.name, option.id)}
          >
            <div>
              <div className="d-flex d-justify-center d-align-center rounded-50 bg-sea-green p-1">
                <img
                  src="/assets/search-Announcement.svg"
                  className="image-contain rounded-50"
                  style={{ height: "18px", width: "18px" }}
                />
              </div>
            </div>
            <div className="font-16 single-line-div">{option?.name}</div>
          </div>
        );
      })}
    </div>
  );
}

export default OptionsPopup;
