import { PulseLoader} from 'react-spinners';

export default function Loader(){
    return (
        <div style={{
           backdropFilter:"blur(15px)",
            zIndex:"8999"}} className={`d-flex d-justify-center d-align-center`}
        >

          <PulseLoader size={15} color="#1CBAAB"></PulseLoader>

        </div>
    );
}

