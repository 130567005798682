import { getToken } from "../../auth/cookies";
import styles from "../../styles/modular css/Login.module.css";
function MobileFooter() {
  return (
    <div className={`bg-blue pt-5 pb-5 ${styles.footerMobile}`}>

    <div className="d-flex-col d-align-center container col-11">
 
      <div className="d-flex d-flex-column d-align-center gap-3">

        {/* Brand Logo */}
        <div className="d-flex d-justify-center">
          <img src="/assets/FutrLogo.png" alt="brandLogo" className="col-6 m-auto" />
        </div>

     
        {/* Navigation buttons */}
        <div className="row gap-3 d-justify-center">
           
           <div className="font-24 text-yellow2">Contact</div>
           <div className="font-24 text-yellow2">Help Center</div>
           <div className="font-24 text-yellow2">About</div>
           <div className="font-24 text-yellow2">futr.tv</div>
           <div className="font-24 text-yellow2">Press</div>
         </div>


           {/* LinkedIn logo */}
      <div className="col-2 d-flex d-justify-center">
        <div><img src="/assets/linkedInLogo.svg" alt="linkedIn" className=""/></div>
      </div>

      <div className="col-12">
              {
                 getToken() ? "" :  <button
                 className="btn btn-sea-green btn-rounded font-20"
                 style={{ width: "100%" }}
               >
                 Sign In or Join
               </button>
              }
             
            </div>
     

          {/* Copyright text */}

          <div className="d-flex d-align-center">
            <p className="font-20 text-white ">© 2023 All Rights Reserved Futr</p>
          </div>
     
 
      </div>


    </div>
    </div>
  );
}

export default MobileFooter;
