import React, { useContext, useState } from "react";
import SignInWithGoogle from "./general/SignInWithGoogle";
import { useNavigate } from "react-router-dom";
import useFirebaseAuth from "../auth/useFirebaseAuth";
import { LoginQuery } from "../utils/auth";
import { setUserCookie } from "../auth/cookies";
import Header from "./general/Header";
import { toastError } from "../utils/Toasters";
import { ToastContainer } from "react-toastify";
import { StateContext } from "./Context/StateContext";
import styles from "../styles/modular css/Login.module.css";
import ForgotPasswordModal from "./general/Modals/ForgotPasswordModal";
import { LinkedIn } from "./general/icons/Icons";
import firebase from "../auth/firebaseConfig"
import SignInWithLinkedIn from "./SignInWithLinkedIn";

function LoginForm() {
  const { loading, setFirstLoading } = useContext(StateContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  const navigate = useNavigate();
  const { signInWithEmailAndPassword, signInWithGoogle } = useFirebaseAuth();

  const loginHandler = async (e) => {
    e.preventDefault();
    setFirstLoading(true);
    signInWithEmailAndPassword(email, password)
      .then((authState) => {
        // console.log(authState);
        LoginQuery()
          .then((response) => {
            setFirstLoading(false);
            return response.json();
          })
          .then((response) => {
            if (response?.status) {
              setFirstLoading(false);
              setUserCookie(response);
              if (firebase.auth().currentUser.emailVerified == true){
                navigate("/dashboard");
              }else{
                toastError("Please verify your email!")
              }
            } else {
              toastError(response.message);
            }
          })
          .catch((error) => {
            setFirstLoading(false);
            console.log("ERROR: ", error);
            toastError(error.message);
          });
      })
      .catch((error) => {
        setFirstLoading(false);
        console.log("ERROR: ", error);
        toastError("Enter valid credentials");
      });
  };

  return (
    <div
      className={`d-flex d-flex-column gap-4 mt-5 ${styles.loginFormWrapper}`}
    >
      <div className="aqua-heading">
        Your future is <br />
        futr
      </div>

      {/* Form */}
      <form
        className="d-flex d-flex-column gap-3 d-justify-center"
        onSubmit={loginHandler}
      >
        <div
          className="d-flex d-align-center form-input-field-div gap-2 p-3"
          style={{ height: "65px" }}
        >
          <img src="/assets/email-envelope-icon.svg" />
          <div className="h-full w-full">
            <input
              className="border-none w-full h-full form-input-field"
              placeholder="Enter your email"
              type="email"
              value={email}
              required={true}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        {/* <input
          className="input-field p-2"
          placeholder="Enter your email"
          style={{ paddingLeft: "8%" }}
          type="text"
          value={email}
          required={true}
          onChange={(e) => setEmail(e.target.value)}
        /> */}

        <div
          className="d-flex d-align-center form-input-field-div gap-2 p-3"
          style={{ height: "65px" }}
        >
          <img src="/assets/password-lock-icon.svg" />
          <div className="h-full col-8 border-right-grey">
            <input
              className="border-none w-full h-full form-input-field"
              placeholder="New Password"
              type={showPassword ? "text" : "password"}
              value={password}
              required={true}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div
            role="button"
            className="d-flex d-justify-center col-2 font-20 f-400 text-sea-green cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? "Hide" : "Show"}
          </div>
        </div>

        {/* <input
          className="input-field p-2"
          placeholder="Password"
          style={{ paddingLeft: "8%" }}
          type="password"
          required={true}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        /> */}

        <p
          className="text-light-grey2 cursor-pointer"
          style={{ marginBlock: "0" }}
          onClick={() => setForgotPassword(true)}
        >
          Forgot Password?
        </p>
        <button className="btn btn-sea-green font-20 l-32" type="submit">
          Sign In
        </button>
        <div className="h4 f-400 l-20 text-center">Or</div>

        <SignInWithGoogle />

        <SignInWithLinkedIn />
      </form>
      <div className="font-20 text-center mt-10">
        Don't have an account?
        <span
          className="text-sea-green font-20 f-600 cursor-pointer"
          onClick={() => navigate("/register")}
        >
          {" "}
          Join Now
        </span>
      </div>
      {/* <ToastContainer /> */}

      {forgotPassword && (
        <ForgotPasswordModal closeModal={() => setForgotPassword(false)} />
      )}
    </div>
  );
}
export default LoginForm;
