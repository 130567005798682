import React, { useContext, useEffect, useState } from "react";
import LoggedInHeader from "./general/LoggedInHeader";
import Company from "./general/cards/dashboard/Company";
import { Link, useLocation } from "react-router-dom";
import { StateContext } from "./Context/StateContext";
import { getMethod, postMethod } from "../utils/apiMethod";
import { toastError } from "../utils/Toasters";
import SpinLoader from "./general/SpinLoader";
import Product from "./general/cards/search/Product";
import SelectProductTypeModal from "./general/cards/dashboard/SearchProductTypes";
import AdvancedFilterModal from "./general/Modals/AdvancedFilterModal";
import { dateFormatter } from "../utils/userAgent";
import TopTenCompanyCard from "./general/cards/search/TopTenCompanyCard";
import styles from "../styles/modular css/SearchCompany.module.css"

function SearchCompany() {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const { setLoading } = useContext(StateContext);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const query = useQuery();
  const [value, setValue] = useState(query.get("value") || "");
  const [type, setType] = useState(query.get("type") || []);
  const [companies, setCompanies] = useState({});
  const [products, setProducts] = useState({});
  const [typeNames, setTypeNames] = useState("");
  const [productSortOrder, setProductSortOrder] = useState("asc");
  const [filterText, setFilterText] = useState("A - Z");
  const [companySortOrder, setCompanySortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("name");
  const [companyType, setCompanyType] = useState(query.get("companyType"));
  const [topTenCompanies, setTopTenCompanies] = useState([]);

  // pagination variables
  const [companyPage, setCompanyPage] = useState(1);
  const [productPage, setProductPage] = useState(1);

  // filter popup
  const [showFilterPopup, setShowFilterPopup] = useState(false); // for company
  const [showProdFilterPopup, setShowProdFilterPopup] = useState(false); // for product

  // dropdown variables and functions
  const [showPopup, setShowPopup] = useState(false);
  const [list, setList] = useState([]);
  let queryFilters = JSON?.parse(query?.get("filters"));
  const [filters, setFilters] = useState(
    queryFilters
      ? queryFilters
      : {
          companyFounded: {
            text: "None",
            val: "",
          },
          customDate: {
            from: null,
            to: null,
          },
          employees: [],
          ipoStatus: {
            text: "None",
            val: "",
          },
          annualRevenue: [],
          funding: [],
          valuation: [0, 10000000000],
          totalFundingRaised: [0, 10000000000],
        }
  );
  let topCompanies = query.get("topCompanies");

  const fetchSelectedOption = (categories) => {
    console.log(categories);
    const updatedSelectedOptions = categories.filter((option) => option.name);
    const updatedSelectedOptionsId = categories.map((option) => option.id);
    setTypeNames(updatedSelectedOptions);
    setType(updatedSelectedOptionsId);
  };

  const handleCheckboxChange = (e, id) => {
    console.log("e: ", e.target.name, "id:", id);

    if (typeNames.includes(e.target.name)) {
      const updatedSelectedOptions = typeNames?.filter(
        (option) => option !== e.target.name
      );
      const updatedSelectedOptionsId = type.filter(
        (optionId) => optionId !== id.toString()
      );
      setTypeNames(updatedSelectedOptions);
      setType([...updatedSelectedOptionsId]);
    } else {
      setTypeNames([...typeNames, e.target.name]);
      setType([...type, id.toString()]);
    }
  };

  // search function
  const searchApiCall = (e) => {
    e.preventDefault();
    setCompanyPage(1)
    searchCompaniesApi();
    // searchProductsApi();
  };

  const searchProductsApi = () => {
    console.log("TYPE in product : ", type);
    setProductLoading(true);
    let data = {};
    if (value.length > 0) {
      data.keyword = value;
    }
    let typeArr = type;
    console.log("typeof : ", typeof typeArr);
    if (typeof typeArr == "string") {
      console.log("string inside");
      typeArr = typeArr.split(",");
      typeArr = typeArr?.filter((item) => {
        console.log(item.length);
        return item.length != 0;
      });
    } else {
      typeArr = typeArr?.filter((item) => {
        console.log(item.length);
        return item.length != 0;
      });
    }

    if (typeArr?.length > 0) {
      data.typeIds = typeArr;
    }
    let raw;
    raw = JSON.stringify(data);
    postMethod(
      `users/searchProduct?limit=6&page=${productPage}&sortOrder=${productSortOrder}`,
      raw
    )
      .then((res) => {
        // setLoading(false);
        setProductLoading(false);
        // console.log("RESSSS: ", res);
        if (res?.status) {
          setProducts(res?.products);
        }
      })
      .catch((error) => {
        // setLoading(false);
        setProductLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  };

  const searchCompaniesApi = () => {
    console.log("TYPE in company : ", type);
    setCompanyLoading(true);
    let data = {};
    if (value.length > 0) {
      data.keyword = value;
    }
    let typeArr = type;
    if (typeof typeArr == "string") {
      typeArr = typeArr.split(",");

      typeArr = typeArr.filter((item) => {
        console.log(item.length);
        return item.length != 0;
      });
    } else {
      typeArr = typeArr.filter((item) => {
        console.log(item.length);
        return item.length != 0;
      });
    }
    if (typeArr.length > 0) {
      data.typeIds = typeArr;
    }
    if (companyType){
      data.companyTypeId = companyType;
    }
      if (
        filters?.companyFounded?.val == "Custom range" &&
        filters?.customDate?.from &&
        filters?.customDate?.to
      ) {
        data.startDate = dateFormatter(
          `${filters?.customDate?.from?.year}-${filters?.customDate?.from?.month}-${filters?.customDate?.from?.day}`
        );
        data.endDate = dateFormatter(
          `${filters?.customDate?.to?.year}-${filters?.customDate?.to?.month}-${filters?.customDate?.to?.day}`
        );
      }

    if (filters?.companyFounded?.val)
      data.companyFounded = filters?.companyFounded?.val;

    if (filters?.employees?.length>0){
      data.employeeCount = filters?.employees?.map(item => item?.val);
    }

    if (filters?.ipoStatus?.val) data.ipoStatus = filters?.ipoStatus?.val;

    if (filters?.annualRevenue?.length>0)
      data.annualRevenue = filters?.annualRevenue?.map(item => item?.val);

    if (filters?.funding?.val == "other") data.funding = ["other"];
    else if (filters?.funding?.length>0) data.funding = filters?.funding?.map(item => item?.val)

    if (filters?.valuation && filters?.valuation?.length == 2)
      data.valuation = `${filters?.valuation[0]} - ${filters?.valuation[1]}`;

    if (filters?.totalFundingRaised && filters?.totalFundingRaised?.length == 2)
      data.totalFunding = `${filters?.totalFundingRaised[0]} - ${filters?.totalFundingRaised[1]}`;

    let raw;
    raw = JSON.stringify(data);
    postMethod(
      `users/searchCompany?limit=6&page=${companyPage}&sortOrder=${companySortOrder}&sortBy=${sortBy}`,
      raw
    )
      .then((res) => {
        // setLoading(false);
        setCompanyLoading(false);
        // console.log("RESSSS: ", res);
        if (res?.status) {
          setCompanies(res?.companies);
        }
      })
      .catch((error) => {
        // setLoading(false);
        setCompanyLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  };

  let sortArr = [
    {
      text: "A - Z",
      sortOrder: "asc",
      sortBy: "name",
    },
    {
      text: "Z - A",
      sortOrder: "desc",
      sortBy: "name",
    },
    {
      text: "Followers",
      sortOrder: "desc",
      sortBy: "followersCount",
    },
    {
      text: "Recently Added",
      sortOrder: "desc",
      sortBy: "createdAt",
    },
  ];

  // useEffect(() => {
  //   setLoading(true);
  //   let str = query.get("type");
  //   console.log("STRRRR: ", str)

  //   console.log(typeof str);

  //   if(str && str.length > 0){

  //     setType(str?.split(","));
  //   }

  //   let raw;
  //   if (type.length > 0) {
  //     console.log(type)
  //     let types = type.split(",");
  //     console.log("TYPES:  ", types)
  //     raw = JSON.stringify({
  //       typeIds: types,
  //     });
  //   }

  //   postMethod(`users/typeIdsToNames`, raw)
  //     .then((res) => {
  //       setLoading(false);
  //       console.log("RESSSS: ", res);
  //       if (res?.status) {
  //          console.log("res.data : ", res.data)
  //          console.log("res.data join : ", res.data.join())

  //          const joined = res.data?.join()

  //         setTypeNames(res.data);

  //         searchApiCall();
  //       }
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       toastError(error.message);
  //       console.log("error.message: ", error.message);
  //     });
  // }, []);

  useEffect(() => {
    // setLoading(true);
    getMethod(`users/getProductTypes`)
      .then((res) => {
        // setLoading(false);
        if (res?.status) {
          setList(res?.data);
        }
      })
      .catch((error) => {
        // setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, []);

  // useEffect(() => {

  //   searchProductsApi();

  // }, [productPage,productSortOrder]);

  const getTopTenCompanies = () => {
    setCompanyLoading(true);
    getMethod(`users/getTopTenCompanies`)
      .then((res) => {
        if (res?.status) {
          setCompanyLoading(false);
          if (res.data && res.data.length > 0) {
            setTopTenCompanies(res?.data);
          }
        }
      })
      .catch((error) => {
        setCompanyLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  };

  useEffect(() => {
    topCompanies == "true" ? getTopTenCompanies() : searchCompaniesApi();
  }, [companyPage, companySortOrder, refresh, topCompanies, sortBy, filters]);

  const advancedFilterHandler = (val) => {
    setFilters(val);
  };

  return (
    <div className="d-grid grid-col-1 gap-5">
      {/* HEADER */}
      <LoggedInHeader />

      <div
        className={`${styles.wrapper} d-grid grid-col-1 gap-5 mt-170`}
      >
        {/* FORM */}

        <div className="d-grid grid-col-1 d-align-center gap-3">
          {/* Heading */}

          <div className="d-flex gap-2 d-align-center">
            <Link to="/dashboard">
              <div className="">
                <img src="/assets/green-arrow-back-circle.svg" />
              </div>
            </Link>

            <div className="text-aqua f-800 font-40">
              {topCompanies == "true"
                ? "FUTR Top 10 Showcase"
                : "Find A Company"}
            </div>
          </div>

          {/* Input boxes */}

          {topCompanies != "true" && (
            <form onSubmit={searchApiCall} className={`${styles.searchForm} d-flex gap-3`}>
              <input
                className="col-12 col-md-4 pl-4 pr-4 bg-none border-none  input-field-solid w-full h-full"
                style={{ height: "58px" }}
                placeholder="Company Name"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />

              {/* <input
                    className="input-field-solid p-2 col-12"
                    placeholder="Type"
                    style={{ paddingInline: "8%" }}
                    value={typeNames}
                    onChange={e => setType(e.target.value)}
                  /> */}

              {/*  TYPES Dropdown field */}
              <div className="col-12 col-md-4">
                <SelectProductTypeModal
                  title={"category"}
                  fetchSelectedOption={fetchSelectedOption}
                  isRequired={false}
                />
              </div>
              <div>
                <AdvancedFilterModal
                  handler={advancedFilterHandler}
                  defaultFilters={filters}
                />
              </div>
              {/* Button */}
              <div className="col-12 col-md-4">
                <button
                  type="submit"
                  style={{ height: "58px" }}
                  className="btn btn-sea-green rounded-20 font-20 col-12"
                >
                  Search
                </button>
              </div>
            </form>
          )}
        </div>

        {/* COMPANIES */}

        {topCompanies == "true" ? (
          <div className="d-flex d-align-center d-justify-center">
            {companyLoading ? (
              <SpinLoader />
            ) : topTenCompanies.length > 0 ? (
              <div className="d-flex d-flex-column gap-2">
                {topTenCompanies?.map((company) => {
                  return (
                    <TopTenCompanyCard
                      name={company?.name}
                      image={company.thumbnailUrl}
                      rating={company?.companyRating}
                      employeeCount={company.employees}
                      description={company.description}
                      id={company.id}
                      followed={company.userFollowsCompany}
                      score={company.userHasScored}
                      video={
                        company?.companyAllContent?.filter(
                          (item) => item.markedVideo == "featuredVideo"
                        )[0]?.videoLink
                      }
                      setRefresh={setRefresh}
                      meetingLink={company?.meetingUrl}
                      categories={company?.companyMarketCats?.map(
                        (item) => item?.marketCategoryDefined
                      )}
                    />
                  );
                })}
              </div>
            ) : (
              <p className="text-capitalize text-grey-3 font-20">
                <em>No results found</em>
              </p>
            )}
          </div>
        ) : (
          <div className="d-flex d-flex-column gap-3 mt-5">
            <div className="d-flex d-flex-wrap gap-4 d-justify-space-between">
              <div className="font-26 f-500">
                Companies (
                {companies?.totalResults
                  ? companies?.totalResults / 10 >= 1
                    ? companies?.totalResults
                    : `0${companies?.totalResults}`
                  : "0"}
                )
              </div>

              {/* PAGINATION */}
              {companies?.results?.length > 0 &&<div className="d-flex d-align-center gap-3">
                <button
                  disabled={companyPage < 2}
                  onClick={() => {
                    if (companyPage > 1) {
                      setCompanyPage(companyPage - 1);
                    }
                  }}
                >
                  <img
                    className="cursor-pointer"
                    src="/assets/arrow-left.svg"
                  />
                </button>
                <div className="font-20 f-500">
                  Showing results : {(companyPage - 1) * companies?.limit + 1} -{" "}
                  {Math.min(
                    companyPage * companies?.limit,
                    companies?.totalResults
                  )}{" "}
                  of {companies?.totalResults}
                </div>
                <button
                  disabled={companyPage >= companies?.totalPages}
                  onClick={() => {
                    if (companyPage <= companies?.totalPages) {
                      setCompanyPage(companyPage + 1);
                    }
                  }}
                >
                  <img
                    src="/assets/arrow-right.svg"
                    className="cursor-pointer"
                  />
                </button>
              </div>}
            </div>

            {/* FILTERS */}
            <div className="d-flex d-justify-end">
              <div
                className="p-3 border border-grey-2 rounded-10 p-relative cursor-pointer"
                onClick={() => setShowFilterPopup(!showFilterPopup)}
              >
                <span className="font-20 text-grey-3">Sort by : </span>
                <span className="f-500 font-20">{filterText}</span>

                {showFilterPopup && (
                  <div
                    className="p-absolute rounded-10 bg-white box-shadow1 d-flex-col d-align-center p-1 gap-5 w-full text-center"
                    style={{ top: "120%" }}
                  >
                    {sortArr.map((item, index) => (
                      <div
                        className="dropdown-items p-1 h6"
                        key={index + 1}
                        onClick={() => {
                          setCompanySortOrder(item.sortOrder);
                          setSortBy(item.sortBy);
                          setFilterText(item.text);
                        }}
                      >
                        {item.text}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="d-flex d-align-center d-justify-center">
              {companyLoading ? (
                <SpinLoader />
              ) : companies?.results?.length > 0 ? (
                <div className={`${styles.cardGrid} gap-2`}>
                  {companies?.results?.map((company) => {
                    return (
                      <Company
                        name={company?.name}
                        image={company.thumbnailUrl}
                        rating={company?.companyRating}
                        employeeCount={company.employees}
                        description={company.description}
                        id={company.id}
                        followed={company.userFollows}
                        score={company.userHasScored}
                        video={
                          company?.companyAllContent?.filter(
                            (item) => item.markedVideo == "featuredVideo"
                          )[0]?.videoLink
                        }
                        setRefresh={setRefresh}
                        meetingLink={company?.meetingUrl}
                        categories={company?.companyMarketCats?.map(
                          (item) => item?.marketCategoryDefined
                        )}
                      />
                    );
                  })}
                </div>
              ) : (
                <p className="text-capitalize text-grey-3 font-20">
                  <em>No results found</em>
                </p>
              )}
            </div>
          </div>
        )}
        {/* PRODUCTS */}

        {/* <div className="d-grid grid-col-1 gap-3">
          <div className="d-flex d-justify-space-between">
            <div className="font-26 f-500">
              Products (
              {products?.totalResults
                ? (products?.totalResults / 10) >= 1
                  ? products?.totalResults
                  : `0${products?.totalResults}`
                : "0"}
              )
            </div>
            <div className="d-flex d-align-center gap-3">
              <button
                disabled={productPage < 2}
                onClick={() => {
                  if (productPage > 1) {
                    setProductPage(productPage - 1);
                  }
                }}
              >
                <img src="/assets/arrow-left.svg" className="cursor-pointer" />
              </button>

              <div className="font-20 f-500 text-center">
                Showing results : {(products?.page - 1) * products?.limit + 1} -{" "}
                {Math.min(
                  products?.page * products?.limit,
                  products?.totalResults
                )}{" "}
                of {products?.totalResults}
              </div>
              <button
                disabled={productPage >= products?.totalPages}
                onClick={() => {
                  if (productPage <= products?.totalPages) {
                    setProductPage(productPage + 1);
                  }
                }}
              >
                <img src="/assets/arrow-right.svg" className="cursor-pointer" />
              </button>
            </div>
          </div>
          <div className="d-flex d-justify-end ">
          <div className="p-3 border border-grey-2 rounded-10 p-relative cursor-pointer" onClick={() => setShowProdFilterPopup(!showProdFilterPopup)}>
              <span className="font-20 text-grey-3">Sort by : </span>
              <span className="f-500 font-20">{productSortOrder === "desc" ? "Z-A" : "A-Z"}</span>

              {
                showProdFilterPopup && (

                  <div className="p-absolute rounded-10 bg-white box-shadow1 d-flex-col d-align-center p-5 gap-5 w-full text-center" style={{top: "120%"}}>

                  <div onClick={()=> {setProductSortOrder("asc")}}>
                   A to Z
                  </div>
                  <div onClick={()=> {setProductSortOrder("desc")}}>
                   Z to A
                  </div>
    
                </div>

                )
              }

           
            </div>

          </div>

          <div className="d-flex d-align-center d-justify-center">
            {productLoading ? (
              <SpinLoader />
            ) : products?.results?.length > 0 ? (
              <div className="d-grid grid-col-3 w-full gap-2">
                {products?.results?.map((product, index) => {
                  return (
                    <Product
                      name={product?.name}
                      description={product?.description}
                      image={product?.productImageUrl}
                      id={product?.id}
                      index={index}
                      category = {product.prodTypes}
                    />
                  );
                })}
              </div>
            ) : (
              <p className="text-capitalize text-grey-3 font-20">
                <em>No results found</em>
              </p>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default SearchCompany;
