import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { removeOnBoardCookie, removeToken, removeUserCookie } from '../../../auth/cookies';
import { StateContext } from '../../Context/StateContext';

function UserSettingsModal({setIsModalActive}) {
    const {setShowLogoutModal} = useContext(StateContext)
    const [isActive, setIsActive] = useState(null)
    const navigate = useNavigate();
    function handleSettingClick(){
        setIsModalActive(0);
        navigate('/settings')

    }

    const logoutHandler = () => {
        removeToken();
        removeOnBoardCookie();
        removeUserCookie();
        navigate('/')
        setIsModalActive(0)
    }

  return (
    <div className="d-flex d-flex-column gap-2 bg-white rounded-10 p-5 col-10 box-shadow2">
      <div
        role="button"
        className={`d-flex d-align-center gap-1 p-3 cursor-pointer
                    `}
      >
        <img width={24} height={24} src="/assets/Settings-black.svg" alt='settings-icon'/>
        <div className="h5" onClick={handleSettingClick}>
          Settings
        </div>
      </div>

      <div
        role="button"
        className={`d-flex d-align-center gap-1 p-3 cursor-pointer
                    `}
        onClick={() => setShowLogoutModal(true)}
      >
        <img width={24} height={24} src="/assets/Logout.svg" alt='logout-icon'/>
        <div className="h5">Logout</div>
      </div>
    </div>
  );
}

export default UserSettingsModal
