import React from "react";
import Header from "../general/Header";
import styles from "../../styles/modular css/ClaimProfile.module.css";
import { useState } from "react";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import Footer from "../general/Footer";
import { useLocation } from "react-router-dom";
import ProfileClaimingForm from "./ProfileClaimingForm";
import ClaimRequestList from "./ClaimRequestList";

function ClaimProfile() {
  const [selected, setSelected] = useState("Profile Claiming");
  let query = new URLSearchParams(useLocation().search);
  let showClaims = query.get("showClaims");

  useEffect(() => {
    if (showClaims == "true") {
      setSelected("Claiming Request");
    } else {
      setSelected("Profile Claiming");
    }
  }, [showClaims]);
  return (
    <div>
      <Header />
      <div className="claim-portal-wrapper mt-170">
        <div className={`${styles.container} container col-12 col-md-11 d-flex d-justify-space-between gap-3 p-5`}>
          <nav
            className={`${styles["sidebar"]} col-12 col-xl-3 col-xxl-2 d-flex d-flex-column gap-2 pt-4 pb-4 pl-5 pr-5 rounded-16`}
          >
            <span className="h5 f-400 l-24 text-grey-9">Manage</span>
            <ul
              className={`${styles.tabList} d-flex d-flex-column gap-1 p-0 m-0`}
            >
              <li
                onClick={() => {
                  setSelected("Profile Claiming");
                  window.open("/claim", "_self");
                }}
                className={`${
                  selected == "Profile Claiming"
                    ? styles.active
                    : styles.inactive
                }`}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.85 17.1C6.7 16.45 7.65 15.9375 8.7 15.5625C9.75 15.1875 10.85 15 12 15C13.15 15 14.25 15.1875 15.3 15.5625C16.35 15.9375 17.3 16.45 18.15 17.1C18.7333 16.4167 19.1875 15.6417 19.5125 14.775C19.8375 13.9083 20 12.9833 20 12C20 9.78333 19.2208 7.89583 17.6625 6.3375C16.1042 4.77917 14.2167 4 12 4C9.78333 4 7.89583 4.77917 6.3375 6.3375C4.77917 7.89583 4 9.78333 4 12C4 12.9833 4.1625 13.9083 4.4875 14.775C4.8125 15.6417 5.26667 16.4167 5.85 17.1ZM12 13C11.0167 13 10.1875 12.6625 9.5125 11.9875C8.8375 11.3125 8.5 10.4833 8.5 9.5C8.5 8.51667 8.8375 7.6875 9.5125 7.0125C10.1875 6.3375 11.0167 6 12 6C12.9833 6 13.8125 6.3375 14.4875 7.0125C15.1625 7.6875 15.5 8.51667 15.5 9.5C15.5 10.4833 15.1625 11.3125 14.4875 11.9875C13.8125 12.6625 12.9833 13 12 13ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z"
                    fill={`${
                      selected == "Profile Claiming" ? "white" : "#555555"
                    }`}
                  />
                </svg>
                <span className="h6 f-500 l-20">Profile Claiming</span>
              </li>
              <li
                onClick={() => {
                  setSelected("Claiming Request");
                  window.open("/claim?showClaims=true", "_self");
                }}
                className={`${
                  selected == "Claiming Request"
                    ? styles.active
                    : styles.inactive
                }`}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.45 20.0005V20V10C4.45 9.57041 4.59784 9.21356 4.9062 8.9052C5.21455 8.59685 5.57089 8.44948 5.99946 8.45H6H7H7.45V8V6C7.45 4.73562 7.89053 3.67087 8.7812 2.7802C9.67186 1.88954 10.7362 1.44939 11.9998 1.45H12C13.2644 1.45 14.3291 1.89053 15.2198 2.7812C16.1105 3.67186 16.5506 4.73621 16.55 5.99978V6V8V8.45H17H18C18.4296 8.45 18.7864 8.59784 19.0948 8.9062C19.4032 9.21455 19.5505 9.57089 19.55 9.99945V10V20C19.55 20.4296 19.4022 20.7864 19.0938 21.0948C18.7855 21.4031 18.4291 21.5505 18.0005 21.55H18H6C5.57041 21.55 5.21356 21.4022 4.9052 21.0938C4.59685 20.7855 4.44948 20.4291 4.45 20.0005ZM5.55 20V20.45H6H18H18.45V20V10V9.55H18H6H5.55V10V20ZM12 13.45L12.0005 13.45C12.4291 13.4495 12.7855 13.5969 13.0938 13.9052C13.4022 14.2136 13.55 14.5704 13.55 15L13.55 15.0005C13.5505 15.4291 13.4032 15.7855 13.0948 16.0938C12.7864 16.4022 12.4296 16.55 12 16.55L11.9995 16.55C11.5709 16.5505 11.2145 16.4031 10.9062 16.0948C10.5978 15.7864 10.45 15.4296 10.45 15L10.45 14.9995C10.4495 14.5709 10.5969 14.2145 10.9052 13.9062C11.2136 13.5978 11.5704 13.45 12 13.45ZM8.55 8V8.45H9H15H15.45V8V6C15.45 5.04868 15.1119 4.22549 14.4432 3.5568C13.7745 2.88812 12.9513 2.55 12 2.55C11.0487 2.55 10.2255 2.88812 9.5568 3.5568C8.88812 4.22549 8.55 5.04868 8.55 6V8Z"
                    stroke={`${
                      selected == "Claiming Request" ? "white" : "#555555"
                    }`}
                    stroke-width="0.9"
                  />
                </svg>

                <span className="h6 f-500 l-20">Claiming Request</span>
              </li>
            </ul>
          </nav>
          {selected == "Profile Claiming" && (
            <ProfileClaimingForm/>
          )}
          {selected == "Claiming Request" && (
            <ClaimRequestList/>
          )}
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default ClaimProfile;
