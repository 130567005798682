import React from "react";
import styles from "../../../../styles/modular css/CategorySlider.module.css";

function CategorySlider({ categories }) {
  return (
    <div className={`${styles.wrapper} col-12`}>
      {categories &&
        categories.map((item, index) => (
          <div
            key={item?.name}
            style={{ width: "max-content", whiteSpace: "nowrap" }}
            className="d-flex gap-1 bg-light-sea-green rounded d-align-center d-justify-center p-1 border"
          >
            <div className="bg-sea-green rounded d-flex d-justify-center d-align-center">
              <img
                height={20}
                width={20}
                src="/assets/search-Announcement.svg"
                alt="category-icon"
              />
            </div>
            <span className="font-16 text-sea-green">{item?.name}</span>
          </div>
        ))}
    </div>
  );
}

export default CategorySlider;
