import { React, useState, useEffect } from "react";

//import components
import ContentVideoCard from "../../general/cards/publicProfile/ContentVideoCard";
import ContentProductCard from "../../general/cards/publicProfile/ContentProductCard";
import SearchBar from "../../general/SearchBar";
import TopContent from "./TopContent";
import { getMethod, postMethod } from "../../../utils/apiMethod";
import { toastError } from "../../../utils/Toasters";
import Loader from "../../general/Loader";
import Pagination from "../../general/Pagination";
import styles from "../../../styles/modular css/Content.module.css"

function Content({companyId}) {
  // Pagination variables

  const [types, setTypes] = useState([])
  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(4);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("");

  const fetchActivePage = (page) => {
    setActivePage(page);
  };

  const fetchLimit = (newLimit) => {
    setLimit(newLimit);
  };

  const [loading, setLoading] = useState(false);

  const [contentData, setContentData] = useState("");

  // Searchbar functions
  const [searchParams, setSearchParams] = useState({
    keyword: "",
    productTypeId: "",
  });

  function fetchSearchValues(keyword, productTypeId) {
    const updatedParams = { keyword: keyword, productTypeId: productTypeId };
    setSearchParams(updatedParams);
  }

  const getTypes = async() => {
    let response = await getMethod(`users/getContentTypes`);
    setTypes(response?.data);
  }

  useEffect(()=>{
    getTypes()
  },[])

  // UseEffect hooks

  useEffect(() => {
    setLoading(true);

    let raw;

    if (searchParams.productTypeId>0) {
    console.log(searchParams.productTypeId);
      raw = JSON.stringify({
        typeIds: searchParams.productTypeId,
        keyword: searchParams.keyword,
      });
    } else {
      raw = JSON.stringify({
        typeIds: "",
        keyword: searchParams.keyword,
      });
    }
    postMethod(
      `users/getCompanyContent/${companyId}`,
      raw
    )
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          let arr = [];
          for (let i in res.data) {
            arr.push({
              catName: i,
              items: res?.data[i]?.items,
            });
          }
          setContentData(arr);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, [
    searchParams.keyword,
    searchParams.productTypeId,
    limit,
    activePage,
    totalPages,
    sortOrder,
    sortBy,
  ]);

  console.log("sort order: ", sortOrder);

  return (
    <div className="d-flex d-flex-column bg-light-blue rounded-16 p-6 gap-5">
      {/* Heading text */}
      <div>
        <h1 className="text-blue font-20">Content</h1>
      </div>

      {/* Video Cards */}

      {/* <TopContent companyId={companyId}/> */}

      {/* Search bar and All Products with pagination */}
      <div className="d-flex d-flex-column gap-2">
        {/* Search bar */}
        <SearchBar
          options={types}
          fetchSearchValues={fetchSearchValues}
        />

        {/* Heading and Sort dropdown */}

        <div className="d-flex d-justify-space-between d-align-center">
          <div>
            <span className="h5">
              Showing results of All Categories ( {contentData?.length} )
            </span>
          </div>
          {/* <div className="d-flex d-align-center gap-1">
            <div className="d-flex-shrink-0">
              <h4>Sort by: </h4>
            </div>
            <div className="d-flex bg-white p-2 gap-1 rounded-8 p-2 col-12">
              <select
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
                className="w-full h-full border-none bg-none outline-none"
              >
                <option value="asc">A - Z</option>
                <option value="desc">Z - A</option>
              </select>
            </div>
          </div> */}
        </div>

        {/* Video List */}

        {loading ? (
          <Loader />
        ) : (
          <>
            {contentData &&
              contentData.map((ele, index) => (
                <div key={index + 1} className="d-flex d-flex-column gap-4">
                  {ele?.items?.length > 0 && (
                    <h1 className="text-blue font-20">{ele?.catName}</h1>
                  )}
                  <div className={`${styles.cardGrid}`}>
                    {ele?.items?.map((content) => {
                      return (
                        <ContentProductCard
                          video={content?.videoLink}
                          title={content?.title}
                          category={content?.contentType?.name}
                        />
                      );
                    })}
                  </div>
                </div>
              ))}
          </>
        )}

        {/* Pagination */}
        {/* <Pagination
          fetchActivePage={fetchActivePage}
          fetchLimit={fetchLimit}
          totalPages={totalPages}
          records={totalResults}
        /> */}
      </div>
    </div>
  );
}

export default Content;
