function CustomerReviews({
  image,
  name,
  description,
  title,
  companyName,
  linkedinUrl,
}) {
  return (
    <div className="d-flex d-justify-space-between rounded-12 bg-white p-5">
      <div className="d-flex d-flex-column d-justify-space-between gap-1">
        <div className="d-flex d-flex-column gap-1">
          <span className="h5 f-500">{companyName}</span>
          <i className="font-12 f-400 text-grey-11">{description}</i>
        </div>
        <div className="d-flex gap-1">
          <img
            src={image}
            className="rounded-50 image-cover"
            style={{ width: "40px", height: "40px" }}
          />
          <div className="d-flex d-flex-column">
            <div className="font-12 f-600">{name}</div>
            <div className="text-grey-12 font-12 f-400">{title}</div>
          </div>
        </div>
      </div>
      {/* Linkedin link */}
      {linkedinUrl && (
        <img
          onClick={() => {
            window.open(linkedinUrl, "_blank");
          }}
          className={`cursor-pointer`}
          width={36}
          height={36}
          src="/assets/linkedinFill.svg"
          alt="linkedin-icon"
        />
      )}
    </div>
  );
}

export default CustomerReviews;
