import React from "react";

function EmailVerificationModal({ onClickBackToLogin, onClickClose }) {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
  };
  return (
    <div className="modal-wrapper d-flex d-align-center d-justify-center" onClick={onClickClose}>
      <div className="d-flex-column bg-white col-4 gap-5 rounded-15 p-5">
       
        <div className="font-20 f-600 d-flex d-justify-center text-center mt-10">
          Email Verification link sent successfully!
        </div>

        <div className="d-flex d-justify-center w-full mt-10">
          <button
            className="btn btn-sea-green col-6"
            onClick={onClickBackToLogin}
          >
            Back to Login
          </button>
        </div>

        {/* <div className="d-flex w-full">
          <div
            role="button"
            className="text-sea-green"
            onClick={onClickClose}
          >
            Close
          </div>
        </div>
        <div className="font-20 f-600 d-flex d-justify-center text-center">
          Email Verification link sent successfully!
        </div>

        <div className="d-flex d-justify-center w-full m-auto">
          <button
            className="btn btn-sea-green col-6"
             onClick={onClickBackToLogin}
          >
           Back to Login
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default EmailVerificationModal;
