import React, { useState, useEffect, useContext } from "react";
import { PulseLoader } from "react-spinners";
import { toastError, toastSuccess } from "../../utils/Toasters";
import CompanyDropdown from "../general/CompanyDropdown";
import { unSecurePostMethod, unSecureGetMethod } from "../../utils/apiMethod";
import { StateContext } from "../Context/StateContext";
import styles from "../../styles/modular css/ClaimProfile.module.css";

function ProfileClaimingForm() {
  const { setLoading } = useContext(StateContext);
  const [companies, setCompanies] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [email, setEmail] = useState("");
  const emailHandler = (e) => setEmail(e.target.value);
  const companyHandler = (item) => setSelectedCompany(item);

  const getData = async () => {
    setLoading(true);
    try {
      let response = await unSecureGetMethod(`auth/getImportedCompanies`);
      if (response.status == true) {
        setCompanies(response?.companies);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastError(error?.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const sendBasicDetailEmail = async (e) => {
    e.preventDefault();
    setBtnLoad(true);
    if (!selectedCompany) {
      setBtnLoad(false);
      toastError("Please select company!");
      return null;
    }
    try {
      var raw = {
        companyId: selectedCompany?.id,
        email: email,
      };
      let response = await unSecurePostMethod(`auth/sendBasicDetailEmail`, raw);
      if (response.status == true) {
        toastSuccess(response?.message);
      }
      setBtnLoad(false);
    } catch (error) {
      setBtnLoad(false);
      toastError(error?.message);
    }
  };
  return (
    <form
      onSubmit={sendBasicDetailEmail}
      className={`${styles["claim-form"]} col-12 col-xl-9 col-xxl-10 d-flex d-align-center d-justify-space-between rounded-8`}
    >
      <div className="col-12 col-lg-7 d-flex d-flex-column gap-4">
        <div className="d-flex d-flex-column gap-1">
          <span className="h2 f-500 l-36 text-black">
            Add following details
          </span>
          <span className="h5 f-400 l-20 text-black">
            Fill out the remaining fields.
          </span>
        </div>
        <div className={`${styles.formInput} d-flex gap-3`}>
          <div className="col-12 col-sm-6 d-flex d-flex-column gap-1">
            <span className="h5 f-400 l-22 text-black">Company name</span>
            <CompanyDropdown data={companies} handler={companyHandler} />
          </div>
          <div className="col-12 col-sm-6 d-flex d-flex-column gap-1">
            <span className="h5 f-400 l-22 text-black">Email Address</span>
            <input
              value={email}
              onChange={emailHandler}
              required
              type="email"
              placeholder="joe@apple.com"
              className="border rounded-8 pl-4 pt-3 pb-3 pr-4"
            />
          </div>
        </div>
      </div>
      {btnLoad ? (
        <div role="button" className="btn btn-sea-green">
          Loading <PulseLoader loading={btnLoad} size={8} color="#FFFFFF" />
        </div>
      ) : (
        <button type="submit" className="btn btn-sea-green">
          Profile Claiming
        </button>
      )}
    </form>
  );
}

export default ProfileClaimingForm;
