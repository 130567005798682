import React from 'react'
import styles from "../../../styles/modular css/VideoBanner.module.css"
function VideoBanner({color, icon, title}) {
  return (
    <div style={{
        backgroundColor:`${color}`
    }} className={`${styles.banner}`}>
      <img width={24} height={24} src={icon} className={`${styles.icon}`}/>
      <span className={`${styles.text}`}>
        {title}
      </span>
    </div>
  );
}

export default VideoBanner