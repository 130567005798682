//import dependencies
import React, { useEffect, useState } from "react";

// import components
import SearchBar from "../../general/SearchBar";
import { postMethod } from "../../../utils/apiMethod";
import { toastError } from "../../../utils/Toasters";
import ViewProduct from "./ViewProduct";
import Loader from "../../general/Loader";
import SpinLoader from "../../general/SpinLoader";
import Pagination from "../../general/Pagination";
import styles from "../../../styles/modular css/Products.module.css"

function Products({ companyId, productTypes }) {
  // Pagination variables

  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(4);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [productSortOrder, setProductSortOrder] = useState("asc");

  const fetchActivePage = (page) => {
    setActivePage(page);
  };

  const fetchLimit = (newLimit) => {
    setLimit(newLimit);
  };

  const [loading, setLoading] = useState(false);

  const [companyProducts, setCompanyProducts] = useState([]);
  const [productId, setProductId] = useState(null);

  // Searchbar functions
  const [searchParams, setSearchParams] = useState({
    keyword: "",
    productTypeId: [],
  });

  function fetchSearchValues(keyword, productTypeId) {
    console.log("KEY: ", keyword, "PROD ID: ", productTypeId);
    const updatedParams = { keyword: keyword, productTypeId: [productTypeId] };
    setSearchParams(updatedParams);
  }

  useEffect(() => {
    setLoading(true);

    let raw;

    if (searchParams.productTypeId > 1) {
      raw = JSON.stringify({
        productTypeIds: searchParams.productTypeId,
        keyword: searchParams.keyword,
      });
    } else {
      raw = JSON.stringify({
        productTypeIds: [],
        keyword: searchParams.keyword,
      });
    }
    postMethod(
      `users/viewCompanyProducts/${companyId}?${
        limit ? `limit=${limit}` : ""
      }&${activePage ? `page=${activePage}` : ""}&${
        productSortOrder ? `sortOrder=${productSortOrder}` : ""
      }`,
      raw
    )
      .then((res) => {
        setLoading(false);
        console.log("RESSSS: ", res);
        if (res?.status) {
          setCompanyProducts(res.data.results);
          setProductId(res.data?.results[0]?.id);
          setActivePage(res.data?.page);
          setTotalResults(res.data?.totalResults);
          setTotalPages(res.data?.totalPages);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  }, [
    searchParams.keyword,
    searchParams.productTypeId,
    limit,
    activePage,
    totalPages,
    productSortOrder,
  ]);

  return (
    <div className="d-flex d-flex-column gap-2">
      {/* All products display */}
      <div className="col-12 d-flex d-flex-column bg-light-blue rounded-16 p-6 gap-4">
        {/* Heading text */}
        <div>
          <h1>Products</h1>
          <p className="font-16 f-400 text-grey-8 m-0">
            Find content and resources for each of the products we offer.
          </p>
        </div>

        {/* Search bar */}
        <SearchBar
          options={productTypes}
          fetchSearchValues={fetchSearchValues}
        />

        {/* Heading and Sort dropdown */}

        <div className="d-flex d-justify-space-between d-flex-wrap gap-2 d-align-center">
          <div>
            <h4>
              Showing results of All Products ( {companyProducts.length} )
            </h4>
          </div>
          <div className="d-flex d-align-center gap-1">
            <div
              className="p-3 border border-grey-2 rounded-10 p-relative cursor-pointer"
              onClick={() => setShowFilterPopup(!showFilterPopup)}
            >
              <span className="font-20 text-grey-3">Sort by : </span>
              <span className="f-500 font-20">
                {productSortOrder === "desc" ? "Z-A" : "A-Z"}
              </span>

              {showFilterPopup && (
                <div
                  className="p-absolute rounded-10 bg-white box-shadow1 d-flex d-flex-column d-align-center p-5 gap-1 w-full text-center"
                  style={{ top: "120%" }}
                >
                  <div
                    className="font-16"
                    onClick={() => {
                      setProductSortOrder("asc");
                    }}
                  >
                    A - Z
                  </div>
                  <div
                    className="font-16"
                    onClick={() => {
                      setProductSortOrder("desc");
                    }}
                  >
                    Z - A
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Products List */}

        {loading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <div className={`col-12 ${styles["card-grid"]}`}>
            {companyProducts?.map((product, index) => {
              return (
                  <div
                    key={index}
                    onClick={() => setProductId(product.id)}
                    className={`${
                      product?.id == productId ? "bg-sea-green" : "bg-white"
                    } col-12 d-flex flex-wrap gap-1 p-2 rounded-8 box-shadow2 cursor-pointer`}
                  >
                    {/* Product Image */}
                    {/* <div
                      className="col-4 rounded-10"
                      style={{ maxHeight: "95px" }}
                    >
                      <img
                        src={
                          product.productImageUrl
                            ? product.productImageUrl
                            : "/assets/Image-Not-Found.png"
                        }
                        className="col-12 rounded-10"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div> */}

                    {/* Product Details */}
                    <div className="col-12 d-flex d-flex-column d-justify-space-around gap-1 p-1">
                      {/* Heading and description */}
                      <div>
                        <h5
                          className={`${
                            product.id == productId
                              ? "text-white"
                              : "text-black"
                          }`}
                        >
                          {product.name}
                        </h5>
                        <p
                          className={`font-12 ${
                            product.id == productId
                              ? "text-white"
                              : "text-grey-9"
                          } f-400 l-16 text-overflow-ellipsis m-0`}
                        >
                          {product.description}
                        </p>
                      </div>

                      {/* Category */}

                      {/* <div className="d-flex gap-1 ox-auto hide-scrollbar cursor-pointer">
                        {product &&
                          product?.prodTypes?.map((category, index) => {
                            return (
                              <div
                                key={index}
                                className="d-flex d-flex-shrink-0 gap-1 p-1 pl-2 pr-2 rounded-5 d-align-center opacity-03"
                                style={{ background: "#F1FBFA" }}
                              >
                                <div className="rounded-50 bg-white row d-justify-center">
                                  <img
                                    src="/assets/PodcastGreen.svg"
                                    style={{ width: "20px", height: "20px" }}
                                  />
                                </div>
                                <div className="font-14 text-sea-green">
                                  {category.productTypeDefined?.name}
                                </div>
                              </div>
                            );
                          })}
                      </div> */}
                      <div
                        style={{
                          background: `${
                            product?.id == productId ? "#FFFFFF4D" : "#1CBAAB33"
                          }`,
                        }}
                        className="col-12 d-flex d-flex-shrink-0 gap-1 p-1 pl-2 pr-2 rounded-5 d-align-center"
                      >
                        <img
                          src={`${
                            product.id == productId
                              ? "/assets/PodcastWhite.svg"
                              : "/assets/PodcastGreen.svg"
                          }`}
                          style={{ width: "20px", height: "20px" }}
                        />
                        <div
                          className={`font-14 ${
                            product?.id == productId
                              ? "text-white"
                              : "text-sea-green"
                          } `}
                        >
                          {product?.productType}
                        </div>
                      </div>
                    </div>
                  </div>
              );
            })}
          </div>
        )}

        {/* Pagination */}
        {/* <div className="d-flex d-justify-space-between d-align-center">
          <div className="col-3 d-flex text-grey-10">
            <div className="d-flex gap-2 d-align-center">
              <div className="font-16">View</div>
              <div className="d-flex d-align-center font-12">
                08
                <img src="/assets/Dropdown.svg" />
              </div>
              <div className="font-14">Products per page</div>
            </div>
          </div>

          <div className="d-flex d-align-center">
            <div className="d-flex d-align-center">
              <img src="/assets/LeftArrow.svg" />
            </div>
            <div className="font-12">1 2 3 4</div>
            <div className="d-flex d-align-center">
              <img src="/assets/RightArrow.svg" />
            </div>
          </div>
        </div> */}

        <Pagination
          fetchActivePage={fetchActivePage}
          fetchLimit={fetchLimit}
          totalPages={totalPages}
          records={totalResults}
        />
      </div>

      {productId ? <ViewProduct id={productId} /> : ""}
    </div>
  );
}

export default Products;
