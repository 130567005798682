import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import LoggedInHeader from './general/LoggedInHeader'
import styles from "../styles/modular css/Notification.module.css"

function Notification() {
  const navigate = useNavigate();
  const location = useLocation();
  
  const backHandler = () => {
    if (location.state?.from) {
      const url = new URL(location.state.from, window.location.origin);
      navigate(url.pathname + url.search); // Navigate with the same query parameters
    } else {
      navigate("/dashboard");
    }
  };
  return (
    <div className="d-flex d-flex-column gap-2">
      <LoggedInHeader />
      <div className="mt-170">
        <div className="container d-flex d-flex-column gap-2 col-11 p-5">
          <div className="d-flex d-align-center gap-2">
            <img
              onClick={backHandler}
              src="/assets/green-arrow-back-circle.svg"
              alt="back-arrow"
              className='cursor-pointer'
            />
            <span className="h1 f-700 l-40 text-aqua">Notifications</span>
          </div>
          <ul className="m-0 p-0 d-flex d-flex-column gap-1">
            <li className={`${styles["list-item"]} d-flex d-align-center d-justify-space-between gap-2 pl-5 pr-5 pt-3 pb-3 border-bottom-dark`}>
              <div className="d-flex d-align-center gap-2">
                <img
                  width={106}
                  height={48}
                  src="/assets/Rubrik.png"
                  alt="company-logo"
                  style={{ objectFit: "contain" }}
                />
                <span className="h3 f-500 l-20 text-black">
                  A new product has been added to Rubrik. Check it out!
                </span>
              </div>
              <button className="btn btn-sea-green">Check out</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Notification