import React from 'react'

function LinkExpired() {
  return (
      <div className="d-flex d-flex-column d-align-center gap-2">
        <span
          className="text-black text-center"
          style={{ fontSize: "96px", fontWeight: "400", lineHeight: "144px" }}
        >
          OOPS !
        </span>
        <span className="h2 f-500 l-36 text-black text-center">
          This link is expired.
        </span>
        <span className="h5 f-400 l-20 text-black">
          Contact <span className="h5 f-400 l-20 text-sea-green">xyz@gmail.com</span> and
          request a new link for yourself.
        </span>
      </div>
  );
}

export default LinkExpired