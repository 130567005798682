import React, { useState, useEffect, useContext } from "react";
import styles from "../../styles/modular css/ClaimProfile.module.css";
import TableHeadings from "./TableHeadings";
import { toastError, toastSuccess } from "../../utils/Toasters";
import { StateContext } from "../Context/StateContext";
import { unSecureGetMethod } from "../../utils/apiMethod";

function ClaimRequestList() {
  const { setLoading } = useContext(StateContext);
  const [searchTerm, setSearchTerm] = useState("");

  const [claimRequests, setClaimRequests] = useState("");

  const getAllClaimRequests = async () => {
    setLoading(true);
    try {
      let response = await unSecureGetMethod(`auth/viewAllClaims`);
      if (response.status == true) {
        setClaimRequests(response?.claims);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastError(error?.message);
    }
  };

  const statusObj = {
    pending_action: {
      class: "status-btn-warn",
      text: "Pending",
    },
    claimed: {
      class: "status-btn-success",
      text: "Claimed",
    },
    discarded: {
      class: "status-btn-error",
      text: "Discarded",
    },
    awaiting_basic_details: {
      class: "status-btn-warn",
      text: "Awaiting basic details",
    },
    awaiting_password_reset: {
      class: "status-btn-warn",
      text: "Awaiting password reset",
    },
  };

  const sendPasswordResetLink = async (id) => {
    setLoading(true);
    try {
      let response = await unSecureGetMethod(`auth/approveRequest/${id}`);
      if (response.status == true) {
        toastSuccess("Password reset link sent!");
      }
      setLoading(false);
    } catch (error) {
      toastError(error?.message);
    }
  };

  const tableHeadArr = [
    {
      title: "SNo.",
      sorting: false,
    },
    {
      title: "Company Name",
      sorting: true,
      filterType: "companyName",
    },
    {
      title: "Claim Sent on",
      sorting: false,
    },
    {
      title: "Claim Req. Sent date",
      sorting: true,
      filterType: "date",
      key: "claimRequestDate",
    },
    {
      title: "Response Received date",
      sorting: true,
      filterType: "date",
      key: "formDate",
    },
    {
      title: "Owner Name",
      sorting: false,
    },
    {
      title: "Owner Title",
      sorting: false,
    },
    {
      title: "Company Email",
      sorting: false,
    },
    {
      title: "Reset req. sent date",
      sorting: true,
      filterType: "date",
      key: "resetPasswordDate",
    },
    {
      title: "Claim status",
      sorting: true,
      filterType: "text",
      key: "status",
    },
    {
      title: "Claim Date",
      sorting: true,
      filterType: "date",
      key: "claimDate",
    },
    {
      title: "",
      sorting: false,
    },
  ];

  useEffect(() => {
    getAllClaimRequests();
  }, []);
  return (
    <>
      <div
        className={`col-12 col-xl-9 col-xxl-10 ${styles.tableWrapper} d-flex d-flex-column`}
      >
        <div
          className={`${styles.tableSearchHeader} col-12 d-flex d-align-center gap-3`}
        >
          <span className="h4 f-600 l-22 text-sea-green">Request details</span>
          <div className="col-6 d-flex d-align-center gap-1 border bg-white pl-3 pr-4 pt-1 pb-1 rounded-8">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                stroke="#A1A1AA"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <input
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="col-12 border-none"
              placeholder="Search with Company Name"
            />
          </div>
        </div>
        <table className={`col-12 ${styles.table}`}>
          <thead>
            <tr className={`${styles.tableHeader} bg-white`}>
              {tableHeadArr.map((item, index) => (
                <TableHeadings
                  key={item?.title}
                  sorting={item?.sorting}
                  title={item?.title}
                  filterType={item?.filterType}
                  claimRequests={claimRequests}
                  setClaimRequests={setClaimRequests}
                  filterKey={item?.key}
                />
              ))}
            </tr>
          </thead>
          <tbody className={`${styles.tableBody}`}>
            {claimRequests &&
              claimRequests
                ?.filter((val) => {
                  if (searchTerm == "") {
                    return val;
                  } else if (
                    val?.companyDetail?.name &&
                    val?.companyDetail?.name
                      ?.toLowerCase()
                      ?.includes(searchTerm?.toLowerCase())
                  ) {
                    return val;
                  }
                })
                ?.map((item, index) => (
                  <tr key={index + 1}>
                    <td className="pl-5">{index + 1}</td>
                    <td>{item?.companyDetail?.name}</td>
                    <td>{item?.claimEmail}</td>
                    <td>
                      {item?.claimRequestDate &&
                        new Date(item?.claimRequestDate)?.toDateString()}
                    </td>
                    <td>
                      {item?.formDate &&
                        new Date(item?.formDate)?.toDateString()}
                    </td>
                    <td>{item?.name}</td>
                    <td>{item?.title}</td>
                    <td>{item?.email}</td>
                    <td>
                      {item?.resetPasswordDate &&
                        new Date(item?.resetPasswordDate)?.toDateString()}
                    </td>
                    <td>
                      <button
                        className={`status-btn ${
                          statusObj[item.status]?.class
                        }`}
                      >
                        <span></span>
                        {statusObj[item.status]?.text}
                      </button>
                    </td>
                    <td>
                      {item?.claimDate &&
                        new Date(item?.claimDate)?.toDateString()}
                    </td>
                    <td>
                      {(!item?.resetPasswordDate ||
                        item?.resetPasswordDate == null) && (
                        <span
                          onClick={() => sendPasswordResetLink(item?.id)}
                          className="h5 f-500 l-24 text-sea-green cursor-pointer cursor-pointer"
                        >
                          Send Reset Password Link
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
        {/* <TablePagination /> */}
      </div>
    </>
  );
}

export default ClaimRequestList;
