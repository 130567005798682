import React, { useState, useRef, useEffect } from 'react'
import useFirebaseAuth from '../../../auth/useFirebaseAuth';
import { toastError, toastSuccess } from '../../../utils/Toasters';
function useOutsideAlerter(ref, handler) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                handler();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}
function ForgotPasswordModal({closeModal}) {
    const wrapperRef = useRef(null)
    const { forgotPassword } = useFirebaseAuth();
    const [email, setEmail] = useState("");
    const handler = () => {
        closeModal()
    };
    const submitHandler = async (e) => {
      e.stopPropagation();
      try {
        await forgotPassword(email);
        closeModal();
      } catch (error) {
        toastError(error.message)
      }
    };



    useOutsideAlerter(wrapperRef, handler);

  return (
    <div className="modal-wrapper d-flex d-align-center d-justify-center">

        <div className="d-flex-column bg-white col-4 gap-5 rounded-15 p-5" ref={wrapperRef}>
            
            <div className="font-24 f-500 d-flex">
            Forgot password?
            </div>

            <div className='border-sea-green rounded-10 mt-5 w-full pl-5' style={{ height: "65px" }}>
                <input placeholder="Enter your Email" className='h-full w-full border-none rounded-10 outline-none font-20' type="email" required={true}
                value={email} onChange={(e)=> setEmail(e.target.value)} />
            </div>

            <div className="d-flex d-justify-center w-full mt-5">
                <button
                className="btn btn-sea-green col-12"  onClick={submitHandler}
                >
                Send verification link 
                </button>
            </div>

        </div>

    </div>
   
  )
}

export default ForgotPasswordModal
