import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import { Pagination, Mousewheel, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/mousewheel";
import "swiper/css/autoplay";
import styles from "../../styles/modular css/Carousel.module.css";
import VideoBanner from "./content/VideoBanner";
import ModalWrapper from "../general/Modals/ModalWrapper";
import VideoModal from "../general/Modals/VideoModal";

function Carousel({ data }) {
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const openModalHandler = () => setOpenModal((prev) => !prev);

useEffect(() => {
  let arr = [];

  // Check and add videos in the desired order
  const featuredVideo = data?.find(
    (ele) => ele.markedVideo === "featuredVideo"
  );
  const firstCallVideo = data?.find((ele) => ele.markedVideo === "videoCall");
  const demoVideo = data?.find((ele) => ele.markedVideo === "demoVideo");

  if (featuredVideo) {
    arr.push({
      videoLink: featuredVideo?.videoLink,
      banner: "/assets/FeaturedIcon.svg",
      title: "Featured",
      color: "#DFE223",
    });
  }

  if (firstCallVideo) {
    arr.push({
      videoLink: firstCallVideo?.videoLink,
      banner: "/assets/FirstCall.svg",
      title: "First call Video",
      color: "#A5E223",
    });
  }

  if (demoVideo) {
    arr.push({
      videoLink: demoVideo?.videoLink,
      banner: "/assets/Demo.svg",
      title: "Demo Video",
      color: "#E2A123",
    });
  }

  setVideos(arr);
}, [data]);


  const scrollToImage = (index) => {
    document.querySelector(".swiper-container")?.swiper.slideTo(index);
  };

  return (
    <div
      className={`${styles.carouselWrapper} col-12 col-xl-8 d-flex d-flex-column gap-2`}
    >
      {/* Buttons */}
      <div className={`${styles.btnWrapper} d-flex gap-2 m-auto d-justify-center`}>
        {videos?.map((item, index) => (
            <button
              className={`btn btn-outline-sea-green`}
              onClick={() => scrollToImage(index)}
            >
              {item?.title}
            </button>
        ))}
      </div>
      {videos?.length > 0 ? (
        <>
          {videos.length == 1 ? (
            <div
              onClick={(e) => {
                setSelectedVideo(videos[0]?.videoLink);
                openModalHandler();
              }}
              className={`${styles["video-container"]} col-12 d-flex d-align-center d-justify-center rounded-10`}
            >
              <>
                <ReactPlayer width="100%" url={videos[0]?.videoLink} />
                <VideoBanner
                  color={videos[0]?.color}
                  icon={videos[0]?.banner}
                  title={videos[0]?.title}
                />
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                  }}
                  className="d-flex d-align-center d-justify-center"
                >
                  <img
                    style={{
                      width: "144px",
                      height: "144px",
                      objectFit: "contain",
                    }}
                    width={144}
                    height={144}
                    className="cursor-pointer"
                    src="/assets/playIcon.svg"
                    alt="play-icon"
                  />
                </div>
              </>
            </div>
          ) : (
            <div className="col-12">
              <Swiper
                slidesPerView={1.4} // Show 1 full slide and part of the next slide
                spaceBetween={20} // Space between slides
                pagination={{
                  clickable: true,
                  renderBullet: (index, className) => {
                    return '<span class="' + className + '">' + "</span>";
                  },
                }}
                mousewheel={true}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 1.4,
                    spaceBetween: 20,
                  },
                  991: {
                    slidesPerView: 1.75,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 1.4,
                    spaceBetween: 20,
                  },
                }}
                modules={[Mousewheel, Pagination, Autoplay]}
                className="col-12 swiper-container mySwiper"
              >
                {videos?.map((item, index) => (
                  <SwiperSlide
                    key={item?.title}
                    onClick={(e) => {
                      setSelectedVideo(item?.videoLink);
                      openModalHandler();
                    }}
                    className={`${styles["video-container"]}`}
                  >
                    {/* Featured Video */}
                    <>
                      <ReactPlayer width="100%" url={item?.videoLink} />
                      <VideoBanner
                        color={item?.color}
                        icon={item?.banner}
                        title={item?.title}
                      />
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          top: "0",
                          left: "0",
                        }}
                        className="d-flex d-align-center d-justify-center"
                      >
                        <img
                          style={{
                            width: "144px",
                            height: "144px",
                            objectFit: "contain",
                          }}
                          width={144}
                          height={144}
                          className="cursor-pointer"
                          src="/assets/playIcon.svg"
                          alt="play-icon"
                        />
                      </div>
                    </>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </>
      ) : (
        <div className="col-12">
          <img
            style={{ width: "100%", height: "100%" }}
            src="/assets/NoVideos.png"
            alt="no-videos"
          />
        </div>
      )}
      {openModal && (
        <ModalWrapper>
          <VideoModal
            url={selectedVideo}
            closeHandler={() => setOpenModal(false)}
          ></VideoModal>
        </ModalWrapper>
      )}
    </div>
  );
}

export default Carousel;
