import { useState, useEffect } from "react";
import firebase from "./firebaseConfig";
import {
  setToken,
  getToken,
  removeToken,
  removeUser,
  removeUserId,
} from "./cookies";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastError, toastSuccess } from "../utils/Toasters";
import {
  getAuth,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
} from "firebase/auth";

export default function useFirebaseAuth() {
  const auth = firebase.auth();
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  googleProvider.addScope("https://www.googleapis.com/auth/contacts.readonly");

  const formatAuthUser = (user) => ({
    uid: user.uid,
    email: user.email,
    name: user.displayName,
    token: user.multiFactor.user.accessToken,
    phone: user.phoneNumber,
    photo: user.photoURL,
    verified: user.emailVerified,
  });

  const authStateChanged = async (authState) => {
    if (!authState) {
      clear();
      return;
    }
    setToken(authState.multiFactor.user.accessToken);
  };

  const clear = () => {
    removeToken();
    removeUserId();
    removeUser();
  };

  const createUserWithEmailAndPassword = (email, password) =>
    auth.createUserWithEmailAndPassword(email, password);

  const signInWithEmailAndPassword = (email, password) =>
    auth.signInWithEmailAndPassword(email, password);

  const signInWithCustomToken = (firebaseToken) =>
    firebase.auth().signInWithCustomToken(firebaseToken);

  const signOut = () => auth.signOut().then(clear);

  const forgotPassword = async (email) => {
    try {
      // Check if the user exists by fetching sign-in methods for the email
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);

      if (signInMethods.length > 0) {
        // If sign-in methods exist, send the password reset email
        await sendPasswordResetEmail(auth, email);
        toastSuccess("Password reset email sent.");
      } else {
        toastError("No user found with this email.");
      }
    } catch (error) {
      toastError(
        "Error fetching sign-in methods or sending reset email: " +
          error.message
      );
    }
  };

  const changePassword = (newPassword, myFunc) => {
    const user = firebase.auth().currentUser;

    user
      .updatePassword(newPassword)
      .then(async () => {
        const newToken = await user.getIdToken(true);
        setToken(newToken);
        myFunc(newToken);
        console.log("NEW token inside firebase auth : ", newToken);

        toastSuccess("Password updated!");
      })
      .catch((error) => {
        console.log("error change pwd: ", error);
        toastError(error.message);
      });
  };

  const signInWithGoogle = () =>
    firebase.auth().signInWithPopup(googleProvider);

  const verifier = () => {
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // Handle response if needed
        },
        "error-callback": (error) => {
          toastError(error.message);
        },
        "expired-callback": () => {
          toastError("Recaptcha expired.");
        },
      }
    );
    return recaptchaVerifier;
  };

  const signInWithPhoneNumber = (phone, appVerifier) =>
    firebase.auth().signInWithPhoneNumber(phone, appVerifier);

  const setRole = (firebaseUid) => {
    firebase.auth().setCustomUserClaims(firebaseUid, { role: "user" });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(authStateChanged);
    return () => {
      unsubscribe();
    };
  }, []);

  // returns function references for using the firebase authentication methods
  return {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    forgotPassword,
    changePassword,
    signInWithGoogle,
    formatAuthUser,
    signInWithPhoneNumber,
    verifier,
    setRole,
    signInWithCustomToken
  };
}
