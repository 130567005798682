import React, { useEffect, useState } from "react";
import CustomerReviews from "../../general/cards/publicProfile/CustomerReviews";
import ResourcesCard from "../../general/cards/publicProfile/Resources";
import { getMethod } from "../../../utils/apiMethod";
import { toastError } from "../../../utils/Toasters";
import Loader from "../../general/Loader";
import ReactPlayer from "react-player";
import ModalWrapper from "../../general/Modals/ModalWrapper";
import VideoModal from "../../general/Modals/VideoModal";
import styles from "../../../styles/modular css/ViewProduct.module.css";

function ViewProduct({ id }) {
  console.log("VIEW PROD ID: ", id);

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const openModalHandler = () => setOpenModal((prev) => !prev);
  const [productDetails, setProductDetails] = useState({
    name: "",
    description: "",
    productImageUrl: "",
    testimonials: [],
    benefits: [],
    resources: [],
    video: "",
    productVideo: "",
  });

  const viewProductHandler = (productId) => {
    console.log("inside: ", productId);

    setLoading(true);
    getMethod(`users/viewProduct/${productId}`)
      .then((res) => {
        console.log("response: ", res);

        if (res?.status) {
          const {
            name,
            description,
            media,
            testimonials,
            benefits,
            productImageUrl,
            productVideo,
          } = res?.productDetails;
          const resources = res?.productDetails?.media?.filter(
            (media, idx) => media.type == "resource"
          );
          const video = res?.productDetails?.media?.filter(
            (media, idx) => media.type == "video"
          );
          setProductDetails({
            ...productDetails,
            name,
            description,
            media,
            testimonials,
            benefits,
            productImageUrl,
            resources,
            video,
            productVideo,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  };

  useEffect(() => {
    viewProductHandler(id);
  }, [id]);

  console.log("product details : ", productDetails);

  return (
    <div>
      {/* Individual product display */}

      <div className="d-flex d-flex-column bg-light-blue rounded-16 p-6 gap-4">
        {loading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            {/* Product description */}
            <div className={`${styles.productDescription} d-flex gap-5`}>
              <div
                className="col-12 col-md-5 d-inline-block p-relative  box-shadow2"
                style={{ height: "256px" }}
              >
                {productDetails?.productVideo ? (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      openModalHandler();
                    }}
                    className="p-relative rounded-12 o-hidden"
                  >
                    <ReactPlayer
                      height={256}
                      width="100%"
                      url={productDetails?.productVideo}
                      className="image-cover rounded-12 w-full h-full"
                    />
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: "0",
                        left: "0",
                      }}
                      className="d-flex d-align-center d-justify-center"
                    >
                      <img
                        style={{
                          width: "144px",
                          height: "144px",
                          objectFit: "contain",
                        }}
                        width={144}
                        height={144}
                        className="cursor-pointer"
                        src="/assets/playIcon.svg"
                        alt="play-icon"
                      />
                    </div>
                  </div>
                ) : (
                  <img
                    src={"/assets/Image-Not-Found.png"}
                    className="image-cover rounded-12 w-full h-full"
                  />
                )}
              </div>
              <div className="col-12 col-md-7">
                <div className="col-11  d-flex d-flex-column">
                  {/* Heading and text section */}
                  <div className=" d-flex d-flex-column">
                    <h2>{productDetails.name}</h2>
                    <p>{productDetails.description}</p>
                  </div>

                  {/* Benefits section */}
                  <div className="d-flex d-flex-column gap-2">
                    <h3>Benefits</h3>
                    <div
                      style={{ columnGap: "32px", rowGap: "8px" }}
                      className={`${styles.benefitsGrid} d-grid grid-col-2`}
                    >
                      {productDetails?.benefits.map((benefit, idx) => {
                        return (
                          <div key={idx} className="d-flex d-align-start gap-1">
                            <img
                              src="/assets/circle-check.svg"
                              style={{
                                width: "18px",
                                height: "18.046px",
                              }}
                              alt="tick-icon"
                            />
                            <div
                              style={{
                                wordBreak: "break-word",
                                overflowWrap: "break-word",
                                whiteSpace: "normal",
                              }}
                              className="font-12 f-400 text-black"
                            >
                              {benefit?.description}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Learn more */}
            {/* <div className="d-flex d-flex-column gap-2">
                            <h4>Learn more</h4>
                            <div className="d-flex gap-1">

                                    <div className="rounded-12 p-relative box-shadow2" style={{maxWidth: "264px",maxHeight: "144px"}}>
                                    
                                            <img src="/assets/LearnMoreImg1.jpeg" className="rounded-12 of-cover image-contain col-12"/>
                                        
                                        <img src="/assets/PlayButton.svg" className="p-absolute col-3 m-auto" style={{left:"0", right:"0" ,top:"0", bottom:"0"}}/>
                                    </div>
                                    <div className="rounded-12 p-relative box-shadow2" style={{maxWidth: "264px",maxHeight: "144px"}} >
                                        
                                            <img src="/assets/LearnMoreImg2.jpeg" className="rounded-12 of-cover image-contain col-12"/>
                                        
                                        <img src="/assets/PlayButton.svg" className="p-absolute col-3 m-auto" style={{left:"0", right:"0" ,top:"0", bottom:"0"}}/>
                                    </div>
                                    <div className="rounded-12 p-relative box-shadow2" style={{maxWidth: "264px",maxHeight: "144px"}}>
                                        <img src="/assets/LearnMoreImg3.jpeg" className="rounded-12 of-cover image-contain col-12"/>
                                        <img src="/assets/PlayButton.svg" className="p-absolute col-3 m-auto" style={{left:"0", right:"0" ,top:"0", bottom:"0"}}/>
                                    </div>
                                    <div className="rounded-12 p-relative box-shadow2" style={{maxWidth: "264px",maxHeight: "144px"}}>
                                        <img src="/assets/LearnMoreImg4.jpeg" className="rounded-12 of-cover image-contain col-12"/>
                                        <img src="/assets/PlayButton.svg" className="p-absolute col-3 m-auto" style={{left:"0", right:"0" ,top:"0", bottom:"0"}}/>
                                    </div>
                                

                            </div>
                        </div> */}

            {/* Resources */}
            <div className="d-flex d-flex-column gap-2">
              <h4>Resources</h4>
              {productDetails.resources.length < 1 ? (
                <div className="font-16">
                  <em>No resources added</em>
                </div>
              ) : (
                <div className={"row gap-1"}>
                  {productDetails?.resources?.map((resource, idx) => {
                    return (
                      <ResourcesCard
                        key={idx}
                        type={resource?.fileType}
                        mediaUrl={resource?.mediaUrl}
                        name={productDetails?.name}
                      />
                    );
                  })}
                </div>
              )}
            </div>

            {/* Customer reviews */}
            <div className="d-flex d-flex-column gap-2">
              <h4>What our customers are saying</h4>
              <div className={`${styles.testimonialGrid} d-grid col-grid-4 gap-2`}>
                {productDetails?.testimonials.length > 0
                  ? productDetails.testimonials.map((item, idx) => {
                      return (
                        <CustomerReviews
                          image={item?.imageUrl}
                          name={item?.customerName}
                          description={item?.description}
                          title={item?.title}
                          companyName={item?.companyName}
                        />
                      );
                    })
                  : ""}
              </div>
            </div>
          </>
        )}
      </div>
      {openModal && (
        <ModalWrapper>
          <VideoModal
            url={productDetails?.productVideo}
            closeHandler={() => setOpenModal(false)}
          ></VideoModal>
        </ModalWrapper>
      )}
    </div>
  );
}

export default ViewProduct;
