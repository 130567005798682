import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBmPQosjU_3qSYNzRcFgONeKf02FokMUwI",
    authDomain: "futr-dev01.firebaseapp.com",
    databaseURL: "https://futr-dev01-default-rtdb.firebaseio.com",
    projectId: "futr-dev01",
    storageBucket: "futr-dev01.appspot.com",
    messagingSenderId: "43321799474",
    appId: "1:43321799474:web:9175ce005a5799f0e05d2b",
    measurementId: "G-FFQPYHP6J2"
  };

if(!firebase.apps.length){
  firebase.initializeApp(firebaseConfig);
}
export default firebase;