import React, { useState } from 'react';

const ReadMoreNew = ({ text, maxLength, showReadMore, classes }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  return (
    <div className={classes}>
      <p className="m-0">
        {isTruncated ? `${text.substring(0, maxLength)}...` : text}
        <b>
        {showReadMore && text.length > maxLength && (
          <span onClick={toggleTruncate} style={{ color: 'black', cursor: 'pointer' }}>
            {isTruncated ? ' Read more' : ' Read less'}
          </span>
        )}
        </b>
      </p>
    </div>
  );
};

ReadMoreNew.defaultProps = {
  maxLength: 250,
  showReadMore: true,
  classes: ""
};

export default ReadMoreNew;
