import React, { useEffect } from 'react';
import { getMessaging, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import useFcmToken from '../../auth/useFcmToken';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Listener() {
    const { fcmToken,notificationPermissionStatus } = useFcmToken();
    // Use the token as needed

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const app = initializeApp({
      apiKey: "AIzaSyBmPQosjU_3qSYNzRcFgONeKf02FokMUwI",
      authDomain: "futr-dev01.firebaseapp.com",
      databaseURL: "https://futr-dev01-default-rtdb.firebaseio.com",
      projectId: "futr-dev01",
      storageBucket: "futr-dev01.appspot.com",
      messagingSenderId: "43321799474",
      appId: "1:43321799474:web:9175ce005a5799f0e05d2b",
      measurementId: "G-FFQPYHP6J2",
    });
    useEffect(() => {
        if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
        const messaging = getMessaging(app);
        const unsubscribe = onMessage(messaging, (payload) => {
            console.log('Foreground push notification received');
            console.log(payload)
            toast.success(payload?.notification?.body, {
                toastId: "sjhddjjk"
            })
        });
        return () => {
            unsubscribe(); // Unsubscribe from the onMessage event
        };
        }
    }, []);

    return <div>
        <ToastContainer/>
    </div>;
}