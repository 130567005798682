import { PuffLoader } from 'react-spinners';

export default function SpinLoader(){
    return (
        <div style={{
           backdropFilter:"blur(15px)",
            zIndex:"8999"}} className={`d-flex d-align-center`}
        >

          <PuffLoader size={60} color="#1CBAAB"></PuffLoader>

        </div>
    );
}

